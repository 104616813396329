@import '../styles//mixins.scss';

.hide {
  display: none !important;
}

.green {
  color: var(--icon-ok-color-secondary);;
}

.font-gray {
  color: var(--button-color);
}

.max-width {
  margin: 0 auto 75px;
  // max-width: 1200px;
  max-width: 1536px;
}

.main-padding {
  padding: 0 10px;
}

.max-width2 {
  margin: 0 auto;
  max-width: 960px;
}

.max-width-small {
  margin: 0 auto;
  max-width: 640px;
}

.max-width-medium {
  margin: 0 auto;
  max-width: 846px;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.shadow2 {
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.16);
}

.shadow3 {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}

.shadow4 {
  // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border: solid 1px #F8F8F8;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.menu-container {
  display: inline-block;
  background: rgba(255, 255, 255, 0.007);
  position: relative;

  .context-menu {
    visibility: hidden;
    z-index: 2;
    right: -9px;
    color: var(--color-1);
    position: absolute;
    background: var(--color-8);;
    display: inline-block;
    min-width: 263px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 3px;

    .menu-item {

      .fancy-switch-button2 .onoffswitch-label:before {
        display: none;
      }
    }
  }

  &:hover .context-menu {
    visibility: visible;
    animation: fadeInFromNone 0.2s ease-out;

    .fancy-switch-button2 .onoffswitch-label:before {
      display: block;
    }

  }

  .context-menu {
    .menu-header {
      padding: 14px 12px;
      margin: 1px;
      cursor: pointer;

      a {
        text-align: right;
        margin: 0;
        font-size: var(--size-2);
        font-weight: 300;
        padding-right: 16px;
      }
    }

    .menu-item {
      padding: 10px 12px;
      margin: 1px;
      cursor: pointer;

      a {
        display: block;
      }

      &:hover {
        background: var(--menu-item-color-hover);
      }
    }
  }
}

.top-black-bar {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  height: 92px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;

  @include mobile {
    max-width: 100vw;
  }

  .icon-menu{
    img{
      height: 30px;
      width: 30px;
    }
  }
}

@keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.circle {
  width: 0;
  height: 0;
  border: 13px solid var(--action-text-color);
  border-radius: 50%;
  color: var(--color-8);
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;

  > i {
    margin-left: -5px;
    margin-top: -7px;
    position: absolute;
    font-size: var(--table-heading-font-size);
  }
}

.black-circle {
  border-radius: 8px;
  border: solid 8px var(--strong-emphasis);
  margin-left: -7px;
  position: absolute;
  margin-top: 10px;
}

.blue-circle {
  border-color: var(--action-text-color);

  &:hover {
    border-color: rgb(32, 81, 121);
  }
}

.gray-circle {
  border-color: var(--paragraph-color-secondary);
  color: var(--table-hd-bg-color);

  &:hover {
    border-color: var(--link-color-selected);
  }
}

.orange-text {
  color: var(--link-color-selected);
}

.image-cropper {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;

  img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
}

.white-box {
  background: var(--color-8);
  width: auto;
}

.tag {
  display: inline-block;
  position: absolute;
  font-size: smaller;
  background: var(--input-background-color);
  border-radius: 10px;
  padding: 3px 7px;
  color: var(--color-8);
  margin-left: 5px;
  cursor: pointer;

  &.green {
    background: var(--icon-ok-color-third);
  }

  &.red {
    background: var(--input-invalid-color);;
  }
}

.orange-tabs {
  position: relative;
  margin-top: 37px;

  .tabs {
    @media (max-width: 450px) {
      text-align: center;
    }

    .tab {
      cursor: pointer;
      display: inline-block;
      padding-bottom: 9px;
      margin-right: 20px;
      word-spacing: 2px;
      color: var(--button-color);

      @media (max-width: 450px) {
        margin: 6px;
      }

      &.selected, &:hover {
        border-bottom: solid 4px var(--link-color-selected);
        color: var(--link-color-selected);
      }
    }
  }
}

.black-tabs-v2 {
  position: relative;
  // margin-top: 37px;

  .tabs {
    .tab {
      cursor: pointer;
      display: inline-block;
      padding: 0 0 9px;
      margin-right: 0;
      // min-width: 100px;
      margin-right: 20px;
      word-spacing: 2px;
      color: var(--color-29);
      opacity: 0.5;
      font-size: var(--size-5);
      font-weight: 300;

      @media (max-width: 875px) {
        padding: 0 6px 19px;
        margin-right: 2px;
      }

      &.selected, &:hover {
        color: var(--color-29);
        opacity: 1;

        .title {
          border-bottom: solid 6px var(--color-30);
        }
      }

      &.selected {
        font-weight: 500;
      }

      &:hover:not(.selected) {

        .title {
          border-bottom: solid 3px transparent;
        }
      }

      // &:first-child {
      //   margin-left: 15px;
      // }

      // &.tab_0 {
      //   margin-left: 24px;
      // }
      // &.tab_7, &.tab_6 {
      //   position: absolute;
      //   right: 0;
      //   margin-right: 0;
      //   padding-right: 0;
      //   min-width: 87px;
      // }

      // &.tab_6 {
      //   right: 100px;
      // }

      .sub-title {
        font-weight: 300;
        margin-left: 0;
        font-size: var(--size-8);
        display: block;
      }

      .title {
        padding: 0 0 10px;
        border-bottom: solid 3px transparent;
      }
    }
  }
}

.blue-tabs {
  position: relative;
  margin-top: 37px;

  .tabs {
    .tab {
      cursor: pointer;
      display: inline-block;
      padding: 0 0 9px;
      margin-right: 0;
      min-width: 100px;
      word-spacing: 2px;
      color: var(--color-2);
      opacity: 0.5;
      font-size: var(--size-3);
      font-weight: 300;

      @media (max-width: 875px) {
        padding: 0 6px 19px;
        margin-right: 2px;
      }

      &.selected, &:hover {
        color: var(--color-2);
        opacity: 1;

        .title, .icon {
          border-bottom: solid 3px var(--color-2);
        }
      }

      &.selected {
        font-weight: 600;
      }

      &:hover:not(.selected) {

        .title, .icon {
          border-bottom: solid 3px transparent;
        }
      }

      // &:first-child {
      //   margin-left: 15px;
      // }

      &.tab_7, &.tab_6 {
        position: absolute;
        right: 0;
        margin-right: 0;
        padding-right: 0;
        // min-width: 87px;
      }

      &.tab_6 {
        right: 100px;
      }

      .sub-title {
        font-weight: 300;
        margin-left: 0;
        font-size: var(--size-8);
        display: block;
      }

      .title {
        padding: 0 0 5px;
        border-bottom: solid 3px transparent;
      }
    }
  }

  &.small-tabs {
    margin-top: 12px;

    .tabs {
      .tab {
        //margin-left: 2px;
        font-size: var(--size-5);
        padding: 0 0 9px;
        margin-right: 39px;

        &:last-child {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }

  &.dark-tabs {
    .tabs {
      padding: 19px 10px 4px 50px;

      .tab {
        color: var(--color-1);
        font-size: var(--size-5);
        font-weight: 600;
        margin-right: 43px;
        min-width: initial;

        .title {
          padding: 0 0 9px;
        }

        &.selected, &:hover {
          .title {
            border-color: var(--color-1);
          }
        }
      }
    }

    .tab-body {
      // border-top: 1px solid var(--color-12);

      .line-seperator {
        // border: 1px solid black;
        // border-style: solid none none none;
      }
    }
  }
}

.black-tabs {
  .tabs {
    background: var(--table-hd-bg-color);
    padding: 30px 10px 0 15px;

    .tab {
      cursor: pointer;
      display: inline-block;
      color: var(--input-background-color);
      padding: 9px 15px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      margin-right: 1px;

      &:hover, &.selected {
        background: var(--color-8);
        color: var(--text-color);
        border-bottom: none;
      }
    }

  }

  &.override {
    .tab:hover, .tab.selected {
      background: var(--color-8) !important;
      color: var(--text-color) !important;
      border-bottom: none !important;
    }
  }
}

.round-tabs {
  position: relative;
  //margin-top: 37px;

  .tabs {

    .tab {
      cursor: pointer;
      display: inline-block;
      padding: 12px 0 9px 16px;
      margin-right: 0;
      min-width: 100px;
      word-spacing: 2px;
      font-size: var(--size-3);
      font-weight: 300;
      border: 1px solid var(--color-12);
      border-left: none;
      position: relative;
      background: var(--color-8);

      .sub-title {
        font-size: var(--size-7);
        color: var(--color-18);
        font-weight: 500;
        display: block;
      }

      .title {
        font-weight: 500;
        font-size: var(--size-2);
        color: var(--color-18);
      }


      &:before, &:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1;
      }

      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: var(--color-12);
        border-width: 9px;
        margin-top: -9px;
      }

      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: var(--color-8);
        border-width: 8px;
        margin-top: -8px;
      }

      &:first-child {
        border-left: 1px solid var(--color-12);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.tab_5 {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &:before, &:after {
          content: none;
        }
      }

      &.tab_6 {
        border-left: 1px solid var(--color-12);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.tab_7, &.tab_6, &.tab_8 {
        position: absolute;
        right: 0;
        margin-right: 0;
        padding-right: 0;
        min-width: 100px;

        &:before, &:after {
          content: none;
        }
      }

      &.tab_7 {
        right: 100px;
      }

      &.tab_6 {
        right: 200px;
      }

      &:hover {
        .sub-title, .title {
          color: var(--color-2);
        }
      }

      &.selected {
        background: var(--color-2);

        .sub-title, .title {
          color: var(--color-8);
        }

        &:after {
          border-left-color: var(--color-2);
        }

      }

    }
  }
}

.grid1_3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
}

.form-double-field {
  margin-top: 20px;
}

.form-triple-field .button-selector .tabs {
  grid-template-columns: repeat(3, 1fr);
}

.big-black-circle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  background: var(--paragraph-color);
  border-radius: 50%;
  width: 130px;
  height: 130px;
  text-align: center;

  h2 {
    font-size: 35px;
    color: var(--circle-text-color);
    margin-top: 20px;
  }

  p {
    font-size: var(--paragraph-font-size);
    color: var(--circle-text-color);
    line-height: 1.1;
    text-align: center;
  }
}

.rdw-editor-main {
  border: solid 1px var(--color-16);
  padding: 0 20px;
  height: 300px;
  word-break: break-all;
  border-radius: 4px;
}

.black-top {
  background: var(--table-hd-bg-color);
  color: var(--table-hd-text-color);
  padding: 10px 30px;
}

.small-pager {
  height: 25px;

  .arrow {
    display: inline-block;
    background: var(--input-background-color);
    color: var(--color-8);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: var(--body-font-size);
    vertical-align: bottom;
    text-align: center;
    margin: 0;
    cursor: pointer;

    &:hover {
      background: var(--paragraph-color-secondary);
    }
  }

  .text {
    font-size: var(--table-heading-font-size);
    color: var(--nav-color);
    margin: 0 13px;
  }
}

.contacts-table {
  tr td {
    background-color: var(--color-8);
    width: 15px;
    color: var(--paragraph-color);
    font-weight: bold;

    &:hover {
      color: var(--table-row-bg-color-hover);
    }
  }

  margin-top: 10px;

  h1 {
    margin-bottom: 15px;
  }

  .table-title {
    background: var(--table-hd-bg-color);
    color: var(--button-color);
    display: grid;
    grid-template-columns: auto auto 1fr auto;

    > div {
      border-right: solid 1px var(--link-color);
      padding: 13px 14px;
      word-spacing: 2px;
      line-height: 2.5;
    }

    input {
      float: right;
      background: var(--paragraph-color);
      border: none;
      color: var(--input-background-color);
      width: 200px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    td:nth-child(6) {
      font-weight: normal;
      color: var(--input-background-color);
    }

    &:first-child td {
      cursor: pointer;
      background: var(--table-heading-bg-color);
      color: var(--color-8);
      font-weight: normal;
      padding: 20px;
      text-align: left;
      border: 0;

      &:hover {
        background: var(--table-heading-bg-color-hover);
      }
    }
  }

  td {
    padding: 20px;
    border-bottom: solid 1px var(--link-color);
    vertical-align: top;
    color: var(--nav-color);

    strong {
      color: var(--strong-emphasis);
    }
  }
}

.black-link {
  color: var(--text-color);
  font-weight: bold;

  &:hover {
    color: var(--action-text-color);
  }
}

.profile-link{
  color: #007dff;
  font-weight: normal;
  font-size: 16px;

  &:hover {
    color: #007dff;
  }
}

.loader-min-height {
  position: relative;
  min-height: 450px;
}

.separator {
  color: var(--input-background-color);
  margin: 0 2px 0 3px;
}

.float-right {
  float: right;
}

main {

  h2 {
    color: var(--paragraph-color-secondary);
    font-size: var(--h2-font-size);
    font-weight: 500;

    &.content-heading {
      padding: 18px 30px;
    }
  }

  .item {
    padding: 0 15px;
  }
}

.edit-job-icon {
  position: absolute;
  right: 16px;
  top: 46px;
  width: 15px;
  height: 15px;
  background: rgba(127, 132, 135, 0.7);
  padding: 10px;
  border-radius: 50%;
  color: var(--paragraph-color);
  cursor: pointer;

  &:hover {
    background: rgba(127, 132, 135, 1);
  }

  .fa {
    font-size: 19px;
    position: relative;
    top: -2px;
  }
}

.dots-vertical, .dots-horizontal {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-top: 4px;

  .dot {
    width: 0.2em;
    height: 0.2em;
    background: var(--action-text-color);
    border-radius: 50%;
    margin-bottom: 2px;
  }

  &:hover, &.active {

    .dot {
      box-shadow: 1px 1px 1px #000;
    }
  }
}
.dots-horizontal {
  transform: rotate(90deg);
  margin-top: -6px;
}

.capitalized {
  text-transform: capitalize;
}

.hidden-menu {
  position: absolute;
  width: 150px;
  right: 14px;
  top: -2px;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  hr {
    background: #979797;
    width: 90%;
  }

  .hidden-menu-item {
    font-size: 14px;
    color: #000;
    line-height: 2.4;
    display: block;
    padding: 2px 0 2px 0;
    text-align: center;

    &:hover {
      background: var(--color-3);
      
      &:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      &:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }

  &.menu-icons {
    width: 171px;

    hr {
      margin: 0 auto 9px;
    }
  }

  .icons {
    padding: 8px 0 8px 14px;
    text-align: left;
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    line-height: 1;
    align-items: center;
    grid-gap: 5px;
    // margin-bottom: 7px;

    &:first-child {
      border-radius: 20px 20px 0 0;
    }

    &:last-child {
      border-radius: 0 0 20px 20px;
    }

    &.comp {
      margin-bottom: 5px;
      padding-bottom: 0;
    }

    &.jobs-icons {
      margin-bottom: 15px;
    }

    .hidden-menu-item {
      text-align: left;
      padding: 0;
      line-height: 1;
    }

    &.hire {
      .hidden-menu-item {
        margin-left: 5px;
      }
    }
  }
}

.circle-close {
  color: var(--paragraph-color-secondary);
  font-size: 26px;
  cursor: pointer;

  &:hover {
    color: var(--link-color-selected);
  }

  &.modal-close {
    position: absolute;
    right: 17px;
    top: 11px;
    z-index: 100;
  }
}


.version-show {
  position: absolute;
  top: 80px;
}

.page-not-found {
  margin-top: 30px;
  text-align: center;
}

.search-not-found {
  margin-top: 81px;

  .back-link {
    margin: 62px auto;
  }
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #404040;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 2px;
  }
}

.custom-scroll-wide {
  // scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a8a8a8;
  }

  &::-webkit-scrollbar-track {
    background: #ededed;
  }
}

.skill-tag {
  display: inline-grid;
  font-size: var(--size-4);
  padding: 1px 5px;
  border: 1px solid var(--color-14);
  border-radius: 3px;
  margin: 0 2px 2px 0;
  color: var(--color-18);

  &.match {
    background: var(--color-14);
    border: 1px solid transparent;
    color: var(--color-8);
  }

  &.mandatory {
    color: var(--color-8);
    background: var(--color-5);
  }
}

.border-radius {
  border-radius: 20px;
}

.main-content-space {
  margin-top: 115px;
  //margin-right: 2px;

  &.jobs-list-page {
    margin-top: 118px;
  }

  &.profile-info {
    margin-top: 92px;
    margin-bottom: 50px;
  }

  &.new-job {
    margin-top: 109px;
  }

  &.single-job-space {
    margin-top: 94px;
  }

  & > .search.recruiter {
    display: none;

    @include mobile {
      display: block;
    }
  }
}

.default-pic-frame {
  border-radius: 50%;
  color: var(--color-8);
  font-size: var(--size-11);
  display: grid;
  align-content: center;
  text-align: center;
  width: 120px;
  height: 120px;
  text-transform: uppercase;

  &.anon {
    background: var(--color-4);
    align-content: center;
    justify-content: center;
  }
}

.no-company {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.mini-label {
  font-size: 12px;
  display: block;
  margin-left: 2px;
}

.wrapped-component-container {
  margin-top: 7px;
  display: grid;
  grid-template-columns: auto 1px 1fr 7px;
  position: relative;
  border-radius: 4px;
  border: solid 1px rgba(112, 112, 112, 0.25);
  background-color: #ffffff;

  .borderless {
    border: none;
  }

  input {
    margin-top: 0 !important;
    display: inline-block;
    padding: 0 0 0 7px;
    height: 34px;
    direction: rtl;
  }
  .place-holder {
    position: relative;
    width: 100%;
    line-height: 1em;
    height: 1em;
    top: 50%;
    right: 0;
    margin-top: -0.5em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.gazmanov {
  border-bottom: 1px solid var(--color-4);
  padding-bottom: 5px;
  margin-bottom: 16px;
  background: #ffffff;

  .gazmanov-top {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
  }
}

.bp3-input-ghost {
  height: 29px;
  border: none;
}

.strange-doc-icon-made-by-drunk-me{
  position: relative;
  width: 17px;
  height: 22px;
  background-color: #d8d8d8;

  &::before{
    content: ' ';
    width: 5.7px;
    height: 5.1px;
    background-color: #707070;
    position: absolute;
    top: 0;
    right: 0;
  }

  &::after{
    content: ' ';
    width: 5px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: -3.2px;
    right: -1.1px;
    transform: rotate(132deg);
  }
}

.column-filter {
  position: relative;

  &:after {
    content: '\25BC';
    margin-left: 8px;
  }

  &.filter-asc:after {
    content: '\25B2';
  }
}

.expander{
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #707070;
  position: absolute;
  transform: rotate(45deg);
  cursor: pointer;
}

.row {
  display: flex;
}

.slash {
  height:1px;
  width: 45px;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(-45deg)
}

.profile-name-capitalized {
  text-transform: capitalize;
}

.loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;

  &:after {
    -webkit-animation: spinAround .5s infinite linear;
    animation: spinAround .5s infinite linear;
    border: 2px solid;
    border-radius: 50%;
    content: "";
    display: block;
    height: 1em;
    width: 1em;
    position: absolute;
    right: 10px;
    top: calc(50% - (1em / 2));
    border-color: transparent transparent #1c2152 #1c2152;
  }
}

.is-discrete {
  background-color: #f75245;
  width: max-content;
  border-radius: 3px;
  padding: 0 6px;
  margin: 0 auto;
  color: #fff;

  label {
    color: inherit;
    font-size: 12px;
    font-weight: bold;
    margin-left: 3px;
  }
}

.quill-bs-container {
  &.inbox {

    .ql-container {
      height: 474px;
      background: #fff;
    }
  }
}

.dragging-handle{
  width: 100%;
  height: 90px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.invisible {
  visibility: hidden;
}

.bp3-button.bp3-minimal:hover {
  background: none;
}

.bp3-button .bp3-icon {
  color: #404040;
}

.bp4-popover2 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: var(--menu-border-radius);

  .bp4-popover2-content {
    border-radius: var(--menu-border-radius);
  }
}

.bp3-menu {
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: var(--menu-border-radius);
}

.bp3-menu-item {
  border-radius: 0;
  font-size: 14px;
  color: #404040;
  align-items: center;
  justify-content: flex-start;

  & > .bp3-menu-item-label {
    color: #8A8A8A;
  }

  & > svg { 
    width: 24px; 
  }
}

.bp4-tooltip2 {
  box-shadow: none;

  .bp4-popover2-content {
    color: #404040;
    font-size: 12px;
    border-radius: 10px;
    background-color: #f1f1f1;
  }
}

.toggle-enter {
  opacity: 0;
  transform: scale(0.9);
}
.toggle-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.toggle-exit {
  opacity: 1;
}
.toggle-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}

.lds-dual-ring {
  z-index: 1;
}