.operator-message-navigate-wrapper {
    position: relative;

    label {
        display: inline-block;
        position: relative;
        margin-top: 7px;
        top: 0;
        left: 0;
        font-size: 12px;
        margin-left: 10px;
        color: #9b9b9b;
    }

    button {
        color: #000;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        z-index: 90;
        background-color: transparent;
    }
}