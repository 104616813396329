.projects-bucket-menu {
  display: inline-block;
  position: relative;
  height: 61px;

  .projects-bucket-menu-hidden {
    display: none;
    position: absolute;
    background: #fff;
    border-radius: 3px;
    min-width: 150px;
    text-align: center;
    top: 60px;
  }

  &:hover {
    .projects-bucket-menu-hidden {
      display: block;
    }
  }

  li {
    display: block;
    margin: 10px 0;
    font-size: 14px;
  }
}
