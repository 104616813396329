.dropdown-with-sub-directories {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    padding-top: 18px;
    padding-bottom: 18px;

    &.normal-view {

      padding: 0;
      height: 37px;
      border-radius: 20px;
      display: flex;
      position: relative;

      h2 {
        font-size: 14px;
        font-weight: normal;
        flex: 1;
        margin-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dropdown-icon {
        margin-top: 12px;
        margin-right: 10px;
      }

      .hidden-menu {
        width: 200px;
      }

      .hidden-menu-item {
        text-align: left;
        padding-left: 20px;
        width: 200px;

        .with-sub-directory {

          .sub-directory {

            right: -205px;
          }

          .button-wrapper {
            right: 5px;
          }
        }

        .label-selected {
          color: #3edf83;
        }
      }
    }

    h2 {
      margin-right: 8px;
    }

    svg {
        display: grid;
        margin-top: -1px;

    }

    .click {
      cursor: pointer;
    }

    .dropdown-icon {
      cursor: pointer;
    }

    .hidden-menu {
      width: 112px;
      left: 0;
      top: 42px;
      cursor: pointer;

      .hidden-menu-item {
        
        &.selected-item label {
          color: var(--color-17);
        }

        label {
          font-weight: normal;
          cursor: pointer;
        }

        .button {
          font-size: var(--size-4);
          font-weight: normal;
          padding: 5px 10px;
      
          &.selector {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            position: relative;
            margin-left: 1px;
            padding-right: 18px;
            background-color: transparent;
            color: black;
      
            &:after,
            &:before {
              border-right: 1px solid;
              content: "";
              display: block;
              height: 8px;
              margin-top: -4px;
              position: absolute;
              transform: rotate(315deg);
              left: 10px;
              top: 20%;
              width: 0;
            }
      
            &:after {
              margin-top: -4px;
              transform: rotate(-315deg);
              left: 15px;
            }
            
            &.close {
              &:after,
              &:before {
                transform: rotate(45deg);
              }
      
              &:after {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }

      .with-sub-directory {
        position: relative;

        .button-wrapper {
          transform: rotate(90deg);
          position: absolute;
          right: -1px;
          top: 1px;
        }

        .sub-directory {
          position: absolute;
          right: -123px;
          top: -2px;
          left: auto;
        }
      }
    }
  }