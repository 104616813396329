.user-details-message-templates-create-template {
  h3 {
    margin: 14px 0;
  }

  .user-details-message-templates-create-template-header {
    margin: 22px 0;
  }

  .form-item {
    margin-bottom: 25px;
  }
  textarea{
    height: 300px;
  }

  footer {
    display: grid;
    grid-template-columns: 1fr 80px 100px;
    align-items: baseline;

    p {
      cursor: pointer;
      font-size: 14px;
      color: #007dff;
    }

    .button {
      padding: 9px 0;
      justify-self: end;
    }

    ul{
      li{
        font-size: 12px;
      }
    }
  }

  .user-details-message-templates-create-template-clear-all{
    justify-self: end;
  }
}
