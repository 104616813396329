.complex-multi-select-wrapper {
    width: 100%;
    position: relative;

    .loading {
        display: flex;

        &:after {
            right: 0;
        }
    }

    label {
        top: 11px;
        left: 6px;
        position: absolute;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #000000;
        z-index: 20;
        pointer-events: none;
    }

    .multi-select {
        display: grid;
        grid-template-rows: auto;
    }

    .tag-multi-render {
        display: inline-block;
        padding: 4px 9.35px;
        margin-right: 2px;
        height: 24px;
        width: fit-content;
        border-radius: 12px;
        border: solid 1px #41a3fb;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        font-size: 12px;
        color: white;
        background-color: #41a3fb;
        margin-left: 5px;
        margin-bottom: 0;
        margin-top: 3px;
        max-width: 190px;
        cursor: default;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;

        &.excluded {
            background-color: var(--color-7);
            border: solid 1px var(--color-7);
        }

        &:first-child {
            margin-top: 7px;
        }

        .change-icon,
        .close-icon {
            position: absolute;
            display: none;
            cursor: pointer;
        }

        .multi-render-item-label {
            top: initial;
            left: initial;
            position: relative;
            font-size: 12px;
            visibility: visible;
            color: white;
        }

        &:hover {
            border: solid 1px #41a3fb;
            background-color: #efefef;
            color: #efefef;
            cursor: default;

            &.excluded {
                border: solid 1px var(--color-7);
            }

            .change-icon {
                display: inline;
                left: 50%;
                transform: translate(calc(-50% - 8px), 3%);

                &.include {
                    path,
                    circle {
                        stroke: #41a3fb;
                    }
                }
            }

            .close-icon {
                display: inline;
                left: 50%;
                transform: translate(calc(-50% + 8px), -5%);

                svg {
                    height: 16px;
                    width: 16px;
                }

                &.included-tag-disabled {
                    right: 0;
                    left: 0;
                    transform: none;

                    svg {
                        width: 100%;
                    }
                }
            }

            .multi-render-item-label {
                visibility: hidden;
            }
        }
    }

    .bp3-popover-target {
        width: 100%;
        display: block;

        .bp3-input {
            box-shadow: none;
            border: none;
            border-radius: 0;
            padding-left: 0;

            .bp3-tag-input-values {
                margin-left: 0px;

                .bp3-input-ghost {
                    margin-bottom: 0;
                    width: 15px;
                    cursor: pointer;
                    padding-left: 5px;
                }

                .bp3-tag {
                    display: flex;
                    background-color: transparent;
                    padding-left: 0;
                    margin: 0;
                    padding-right: 0;

                    .bp3-fill {
                        margin-right: 0;
                    }

                    .tag-multi-render {
                        margin-bottom: 0;
                        margin-top: 0;
                        margin-left: 4px;
                        margin-right: 3px;
                    }

                    .title {
                        margin-top: 7px;
                        margin-bottom: 10.5px;
                        margin-left: 2px;
                    }

                    .bp3-tag-remove {
                        position: absolute;
                        right: 15px;
                        top: 7px;
                        display: none;
                    }

                    .mandatory {
                        color: white;
                    }

                    button {
                        display: inline;
                        color: black;

                        svg {
                            padding-top: 1px;
                        }
                    }
                    &:first-child {
                        display: flex;
                        width: 100%;
                        background: transparent;
                        background-color: transparent;
                        border-radius: 0;
                        border: none;
                        color: #979797;
                        font-size: 14px;
                        font-weight: normal;
                        padding: 0;
                        margin-left: 3px;

                        button {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.focus {
        .bp3-input {
            border-bottom: solid 1px var(--color-1);
            padding-bottom: 2px;
        }

        &.invalid {
            .bp3-input {
                border-bottom: solid 1px var(--color-7);
            }
        }
    }

    &.disabled {
        pointer-events: none;

        label {
            color: #d8d8d8;
        }
    }

    .arrow-icon {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        margin-left: 1px;
        padding-right: 0px;
        background-color: transparent;
        color: black;
        display: grid;

        &:after,
        &:before {
            border-right: 1px solid;
            content: "";
            display: block;
            height: 8px;
            position: absolute;
            transform: rotate(45deg);
            right: 5px;
            top: 15px;
            width: 0;
            z-index: 1;
            pointer-events: none;
        }

        &:after {
            transform: rotate(-45deg);
            right: 10px;
            z-index: 1;
        }
    }

    .plus-icon {
        position: relative;
        margin-left: 1px;
        padding-right: 0px;
        background-color: transparent;
        color: var(--color-2);
        display: grid;
        font-size: 24px;
        font-weight: normal;
        &:after,
        &:before {
            content: "+";
            display: block;
            position: absolute;
            right: 3px;
            top: 4px;
            z-index: 1;
            pointer-events: none;
        }
        cursor: pointer;
    }

    .suggestions-list {
        margin-top: 15px;
        margin-left: 0px;

        .suggestions-title {
            color: black;
            font-size: 10px;
            margin-left: 7px;
        }

        .suggest-item {
            margin: 4px 0 4px 7px;
            font-size: 14px;
            border: none;
            color: #007dff;
            padding-left: 0;
            cursor: pointer;

            &.all {
                display: none;
            }
        }
    }
}

.bp3-portal {
    z-index: 51;

    .bp3-multi-select-popover {
        border-radius: 20px;

        .bp3-popover-content {
            border-radius: 20px;
        }
    }

    .bp3-menu {
        cursor: pointer;

        .outer-multi-item {
            width: 190px;

            &.active {
                background-color: initial;

                label {
                    cursor: pointer;
                }
            }

            &.selected {
                label {
                    font-weight: inherit;
                    cursor: pointer;
                }
            }
        }
    }
}
