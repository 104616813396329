@import '../../../styles/mixins.scss';

.main-dashboard-wrapper {
    width: initial;

    .top {
        height: 68px;
        justify-content: center;
        margin: auto 0;

        .top-inner {
            margin: 0 auto;
            max-width: 1536px;
            height: 100%;
            display: grid;
            align-items: center;
        }
    }

    .dashboard-wrapper {
        margin: 13px auto 0px;
        padding-bottom: 62px;

        &.max-width {
            max-width: 1536px;
        }

        .blue-tabs {
            margin-top: 36px;

            .tab-container {

                .tabs {

                    border-bottom: 1px solid var(--color-22);

                    .tab {
                        font-size: var(--size-6);
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        color: var(--color-4);
                        padding: 0 0 6px;

                        &.selected {
                            font-weight: bold;
                            color: var(--color-26);
                        }
                    }

                }

                .tab-body {
                    margin-top: 38px;

                    > .lds-dual-ring {
                        display: grid;
                        height: 70vh;
                        align-items: center;
                    }
                }
            }
        }
    }

    & > .search.recruiter {
        display: none;

        @include responsive-until($md) {
            display: block;
        }
    }

    @include mobile {
        .top {
            padding: 0 16px;
        }

        .dashboard-wrapper {
            padding: 16px 16px 24px;

            .blue-tabs {
                margin-top: 0;
            }

            .blue-tabs .tabs {
                display: flex;
                gap: 16px;
            }

            .blue-tabs .tab-container .tab-body {
                margin-top: 24px;
            }

            .blue-tabs .tabs .tab .title {
                font-size: 20px;
            }
        }

        .dashboard-activities-wrapper {
            display: block;

            .calender {
                display: none;
            }

            .dashboard-wrapper-top, .dashboard-wrapper-bottom {
                display: flex;
                flex-direction: column;
                gap: 16px;
                height: auto;

                & > * {
                    border-radius: 10px;
                    border: 1px solid #EEEFF2;
                }
            }

            .more-menu {
                display: none;
            }

            .column {
                display: flex;
                flex-direction: column;
            }

            .column .bottom {
                margin: 0;
                border-top: none;

                .job-title {
                    border-top: solid 1px var(--color-12);
                    padding-top: 25px;
                    margin: 0 25px;
                }
            }

            .column .line1,
            .column .bottom .line2 {
                grid-template-columns: 1fr 1fr;
                padding-left: 0;
                margin-top: 24px;
            }

            .dashboard-status-item-wrapper h2 {
                align-items: center;
            }
        }

        .dashboard-activities-conversations-wrapper .content {
            margin-top: 8px;
        }

        .dashboard-activities-all-wrapper .column {
            gap: 16px;
        }

        .dashboard-activities-people-wrapper .content {
            margin: 0;
            margin-top: 24px;
            padding: 16px;

            .top1, .bottom {
                grid-template-columns: 1fr 1fr;
            }

            .bottom {
                margin-top: 24px;
            }
        }

        .dashboard-activities-jobs-wrapper .column {
            padding-bottom: 16px;
        }

        .dashboard-candidates-today-jobs-wrapper .column {
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 24px;
            margin-top: 24px;
        }

        .dashboard-activities-activities-wrapper {
            min-height: 300px;

            .more-menu {
                display: block;
            }

            .content {
                margin: 0;
                margin-top: 40px;
                padding: 8px;

                display: flex;
                flex-direction: column;
                gap: 32px;
            }

            .content .top1,
            .content .bottom {
                display: flex;
                justify-content: flex-start;
                margin-top: 0;

                & > div:nth-child(even) {
                    display: none;
                }

                & > div {
                    width: 25%;
                }
            }
        }
    }
    
    .actions {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }
}
