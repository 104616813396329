.complexInputContainer {
    &.disabled {
        pointer-events: none;
        color: #d8d8d8;
        input {
            color: #d8d8d8; 
        }
    }
        position: relative;
    .rtl {
        right: 0;
    }
    .placeholder {
        position: absolute;
        top: 10px;
        font-size: var(--size-314);
        line-height: 21px;
        color: #C7C7C7;
        font-family: Ploni DL 1.1 AAA;
        font-style: normal;
        font-weight: normal;
        padding-left: 7px;
    }
    .icon {
        position: absolute;
        bottom: 3px;
        right: 0
    }

    .input {
        border-radius: 0;
        border: 1px solid  var(--color-29);
        border-style: none none solid none;
        padding: 0 25px 0 7px;

        &.invalid {
            border-color: var(--color-7);
        }
    }

}
