.dashboard-status-item-wrapper {

    position: relative;
    display: grid;
    justify-content: center;


    h1, .two-values-wrapper {
        font-size: var(--size-14);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1em;
        letter-spacing: normal;
        color: var(--color-18);
        height: 42px;
        text-align: center;
        display: grid;
        align-items: center;
        margin: 0 auto;
        display: inline-block;
        position: relative;

        &.blue1 {
            color: var(--color-26);
        }

        &.red {
            color: var(--color-7);
        }

        &.green {
            color: var(--color-19);
        }
        

        &.loading {
            
            background: transparent;

            &:after  {
                border-color: var(--color-21) var(--color-21) var(--color-3) var(--color-3) !important;                
           }
 
        }

        a {
            color: inherit;
        }

        .dot {

            font-size: 16px;
            &:before {
                position: absolute;
                color: var(--color-26);
                content: "•";
                top: -22px;
                right: -12px;
              }
        }
    }

    h2 {
        font-size: var(--size-4);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: var(--color-18);
        height: 42px;
        display: grid;
        margin-top: 14px;
        text-align: center;

        &.blue {
            font-weight: bold;
            color: var(--color-26)
        }
    }

    h3 {
        font-size: var(--size-6);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }

    .two-values-wrapper {
        display: grid;
        grid-template-columns: 40px 40px;

        .number-top-wrapper {

            position: relative;
            height: 100%;

            .top2 {
                position: absolute;
                top: -5px;
                right: 7px;
                text-align: right;
            }
        }

        .number-bottom-wrapper {

            position: relative;
            height: 100%;
            margin-left: 7px;

            .bottom2 {
                position: absolute;
                bottom: -8px;
            }
        }

    }



}