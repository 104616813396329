.status-bar {
    
    text-align: center;
    display: flex;
    justify-content: center;

    h5 {
        margin-left: 3px;
        margin-top: 2px;
        font-size: var(--size-2);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: var(--color-10);
        align-self: center;
    }

    .status {
        position: relative;

        &:before {
          content: "•";
          font-size:20px;
          
          color: var(--color-7);
          position: relative;
        }

        &.failed {
          &:after {
            font-size: 18px;
            content: "X";
            position: absolute;
            font-weight: bold;
            top: 1.5px;
            left: -1px;
            color: rgb(177, 175, 175); 
          }
        }

        &.green {
          &:before {
            color: var(--color-19);
          }
        }

        &.red {
          &:before {
            color: var(--color-7);
          }
        }

        &.yellow {
          &:before {
            color: var(--color-24);
          }
        }

        &.grey {
          &:before {
            color: var(--color-4);
          }
        }

        &.blue {
          &:before {
            color: var(--color-2);
          }
        }

        &.purple  {
          &:before {
            color: var(--color-34);
          }
        }

        &.purple  {
          &:before {
            color: var(--color-34);
          }
        }

        &.azure  {
          &:before {
            color: var(--color-35);
          }
        }

        &.pink  {
          &:before {
            color: var(--color-36);
          }
        }
      }

      .status-bar-text {
        font-size: var(--size-314);
      }
}