.search-results-title-wrapper {
    h1 {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #404040;
        margin: 0;
        margin-left: 20px;
    }

    h2 {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #000000;
    }

    header {
        border-bottom: solid 1px #979797;
        padding-left: 17px;
        display: grid;
        grid-template-columns: 30px auto 276px;
        margin-top: -1px;


        .customCheckbox {
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
            }

            input[type="checkbox"]:before {
                width: 10px;
                height: 5px;
                top: 6px;
            }

            input[type="checkbox"]:after {
                width: 18px;
                height: 18px;
            }
        }

        .all {
            padding-top: 5px;
        }

        .status-wrapper {
            display: flex;
            margin-left: 62px;
            margin-top: 6px;

            .status {
                position: relative;
                margin-left: 6px;
                display: flex;

                .available, .open {
                    &:before {
                        content: "•";
                        color: var(--color-19);
                    }
                }

                .maybe, .hold {
                    &:before {
                        content: "•";
                        color: var(--color-24);
                    }
                }

                .unavailable, .close {
                    &:before {
                        content: "•";
                        color: var(--color-7);
                    }
                }

                .dropdown-with-sub-directories {
                    padding-top: 0;
                    display: flex;
                    align-items: flex-start;

                    h2 {
                        margin-left: 4px;
                    }

                    .arrow {
                        top: 25%;
                        margin-left: -1px;
                    }

                    .hidden-menu {
                        top: 24px;
                        left: -9px;
                        width: 150px;

                        .hidden-menu-item {
                            text-align: start;
                            padding-left: 10px;

                            &:first-child {
                                padding-left: 20px;
                            }
                        }

                        .available, .open {
                            &:before {
                                content: "•";
                                color: var(--color-19);
                                padding-right: 4px;
                            }
                        }

                        .maybe, .hold {
                            &:before {
                                content: "•";
                                color: var(--color-24);
                                padding-right: 4px;
                            }
                        }

                        .unavailable, .close {
                            &:before {
                                content: "•";
                                color: var(--color-7);
                                padding-right: 4px;
                            }
                        }
                    }
                }
            }
        }

        .activities {
            display: grid;
            grid-template-columns: 36px 36px 41px 44px 53px 42px auto;

            &.jobs {
                grid-template-columns: 34px 34px 35px 30px auto;
                justify-content: center;
                margin-left: 144px;
                text-align: center;
            }

            .dropdown-with-sub-directories {
                padding-top: 0px;
                align-items: start;
            }

            .remove {
                margin-top: 6px;
                cursor: pointer;
            }

            .send-cv {
                margin-top: 7px;
                cursor: pointer;
            }

            .send-to-suggest {
                margin-top: 3px;
                cursor: pointer;
            }

            .add-to-matches {
                margin-top: 7px;
                cursor: pointer;

                svg {
                    width: 16px;
                    height: 14px;
                }
            }

            .mail {
                cursor: pointer;
                svg {
                    width: 16px;
                    height: 12px;
                    margin-top: 10px;

                    #a {
                        fill: black;
                    }

                    #b {
                        stroke: black;
                    }
                }
            }

            .projects {
                .dropdown-with-sub-directories {

                    .hidden-menu {
                        width: max-content;
                        min-width: 95px;
                        top: 35px;
                        left: -15px;
                    }

                    .hidden-menu-item {
                        padding-left: 10px;
                        text-align: start;
                    }

                    .arrow {
                        top: 54%;
                    }
                }

                svg {
                    margin-top: 7px;
                }
            }

            .more-menu {
                position: relative;
                cursor: pointer;
                margin-top: 2px;

                .action-item-more-menu {
                    position: absolute;
                    top: 0;
                    right: 18px;          

                    .hidden-menu {
                        .hidden-menu-item {
                            margin: 0;
                            padding-left: 5px;
                            padding-top: 10px;
                            padding-bottom: 10px;

                            svg {
                                justify-self: center;
                            }
                        }

                        .add-to-job {
                            svg {
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
}
