.custom-include-operator-wrapper {
    
    position: relative;
    border: 1px solid #e5e5e5;

    margin-top: 10px;
    padding-left: 10px;

    label {
        display: inline-block;
        position: relative;
        margin-top: 5px;
        top: 0;
        left: 0;
        font-size: 12px;
    }

    .message-wrapper {

        margin-top: 5px;
        margin-bottom: 5px;
        .customCheckbox {
    
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
            }
    
            input[type="checkbox"]:before {
                width: 10px;
                height: 5px;
                top: 6px;
            }
    
            input[type="checkbox"]:after {
                width: 18px;
                height: 18px;
            }
        }

        .operator-message {
            margin-left: 10px;
            font-size: 12px;
            margin-top: 2px;
        }
    }


}