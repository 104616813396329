

.object-card {
  display: flex;
  justify-content: start;
  align-items: center;
  .object-image {
    margin-right: 15px
  }
  .object-infor {
    display: flex;
    flex-direction: column;
    .name {
      text-transform: capitalize;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .default-pic-frame,
.default-image-component > img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  font-size: 16px;
}

.default-image-component > svg {
  width: 60px;
  height: 60px;
}

}