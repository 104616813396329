@import '../../../styles/mixins.scss';

.advanced-search {

  .max-width {
    max-width: 1536px;
  }

  .main-search-component {
    padding-top: 218px;
    padding-bottom: 50px;
  }

  .advanced-search-top {
    position: fixed;
    width: 100%;
    top: 92px;
    z-index: 1;
  }

  .styled-radio-buttons {
    margin-top: 0;
  }

  label {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    margin-left: 1px;
  }

  .gazmanov {
    padding-bottom: 15px;
  }

  .gazmanov-top {
    display: grid;
    grid-template-columns: 1.08fr 1.1fr 1fr;
    margin-top: 13px;
    max-width: 1536px;

    label {
      font-size: 14px;
      font-weight: normal;
    }

    .left {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: baseline;
      grid-gap: 11px;
      margin-top: 5px;

      .styled-radio-buttons {
        width: 238px;

        .styled-radio-button {
          height: 30px;
        }
      }
    }

    .center {
      display: flex;
      align-items: center;

      label {
        // flex: 1;
        // width: 100%;
        margin-right: 10px;
        white-space: nowrap;
      }
    }

    .right {
      .clear-all {
        cursor: pointer;
        font-size: var(--size-3);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: var(--color-26);
        background: transparent;
        margin-right: 30px;
      }

      .button {
        padding: 10px 36px;
        font-size: 16px;
      }
    }

    .top-job-for {
      legend {
        font-size: 14px;
        line-height: 0.4;
      }

      .top-job-for-warp {
        display: grid;
        grid-template-columns: auto 1fr;
        margin-top: 8px;
        grid-gap: 14px;

        a,
        p {
          text-decoration: underline;
          color: var(--color-2);
        }
      }

      img,
      svg {
        width: 30px;
        height: 30px;
        border: 1px solid #979797;
      }

      h2 {
        font-size: 18px;
        margin-bottom: 4px;
        line-height: 1;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .styled-radio-buttons {
    .styled-radio-button {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .gazmanov-job-advanced-search-top {
    padding-bottom: 3px;
  }

  .roles-item {
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: 41px;
    margin-bottom: 33px;

    label {
      margin-top: 2px;
      display: block;
      margin-bottom: 7px;
    }
  }

  .skills-item {
    padding-bottom: 34px;

    .multiselect-wrapper {
      margin-top: 2px;
    }

    label {
      margin-top: 2px;
    }

    .styled-radio-buttons {
      margin-left: 15px;
      width: 100px;

      .styled-radio-button {
        height: 30px;
      }
      margin-bottom: 10px;
    }
  }

  .bp3-input-ghost {
    height: 29px;
    margin-bottom: 2px;
  }

  .category {
    .styled-radio-buttons {
      margin-left: 15px;
      width: 100px;

      .styled-radio-button {
        height: 30px;
      }
      margin-bottom: 10px;
    }
  }

  .roles {
    border: solid 1px #979797;
    padding: 9px 21px 30px 21px;
    min-height: fit-content;
  }

  .roles-wrapper {
    .role-item {
      margin: 2px 8px 8px 0;
    }

    .role-button {
      padding: 9px 7px;
    }
  }

  .suggestions {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 21px;
    // align-items: center;
    grid-gap: 14px;
    // margin-bottom: 20px;
  }

  .suggest-item {
    border: solid 1px #979797;
    font-size: 14px;
    padding: 0 6px;

    &.all {
      display: none;
    }
  }

  .suggestions-title {
    color: #707070;
    font-size: 12px;
    font-weight: bold;
  }

  .border-item {
    border-top: 1px solid #979797;
  }

  .locations-item,
  .recruiters {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 41px 0;
    grid-gap: 39px 19px;
  }

  .styled-radio-buttons {
    width: 200px;

    .styled-radio-button {
      height: 36px;
    }
  }

  .school-wrap {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 39px 19px;
    padding: 41px 0;
  }

  .id-wrap {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 39px 19px;
    padding: 41px 0;
  }

  .suggestions-list {
    // height: 30px;
    display: grid;
    align-items: center;
  }

  .bp3-input {
    //height: 36px;

    //.bp3-tag-input-values{
    //  margin-top: 0px;
    //}
  }

  .form-item {
    label {
      margin-bottom: 4px;
      display: block;
    }

    .multiselect-wrapper {
      margin-top: 0;
    }
  }

  .multiselect-wrapper .bp3-tag-input-values,
  .suggestion-wrapper .bp3-tag-input-values {
    margin: 1px;
  }

  .multiselect-wrapper .bp3-tag,
  .suggestion-wrapper .bp3-tag {
    padding: 5px;
    margin: 0 5px 0 0;
  }

  .ranger-chuck-wrapper {
    position: relative;

    &.exclude {
      input {
        color: red;
      }
    }
  }

  .ranger-chuck {
    border-radius: 4px;
    box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    height: fit-content;
    width: 114%;
    padding: 15px 20px;
    position: absolute;
    top: 65px;
    z-index: 3;
  }

  .input-range__label-container {
    display: none;
  }

  .salary-type {
    margin-bottom: 31px;
  }

  .range-inputs {
    margin-top: 17px;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-gap: 11px;
    text-align: center;

    .to {
      margin-top: 9px;
    }

    input {
      text-align: center;
      padding: 0;
    }
  }

  .button-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 38px;

    .add-buttons {
      display: flex;
      justify-content: flex-end;

      .exclude {
        margin-right: 20px;
        color: red;
      }
    }
  }

  .general {
    .locations-item {
      margin-bottom: 11px;
    }
  }

  .sectors {
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: 41px;
    padding-top: 41px;
    padding-bottom: 33px;

    label {
      margin-top: 2px;
      display: block;
      margin-bottom: 7px;
    }

    .industries {
      border: solid 1px #979797;
      padding: 9px 21px 30px 21px;
      min-height: fit-content;
    }
  }

  @include mobile {
    .gazmanov.advanced-search-top {
      padding: 8px 16px;
      margin-bottom: 0;
    }

    .gazmanov-top {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 0;

      .left, .center, .right {
        display: flex;
        // justify-content: flex-end;
        gap: 24px;

        & > label { 
          flex-shrink: 0; 
          margin: 0; 
        }

        & > input { 
          height: 32px; 
          padding: 0 12px; 
        }

        & > .clear-all, & > .button { 
          font-size: 14px; 
          margin-right: 0; 
          padding: 6px 12px;

        }
      }

      .right {
        justify-content: flex-end;
      }

      .left .styled-radio-buttons {
        width: 100%;
      }
    }

    .main-search-component {
      padding: 24px 16px 40px;
      padding-bottom: 40px;
      margin-top: 200px;

      &.people-search {
        margin-top: 250px;
      }

      & > div:first-child {
        border: none;
        padding-top: 0;
      }
    }

    .locations-item, .recruiters, .school-wrap, .id-wrap{
      width: 100%;
      padding-top: 24px;
      padding-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;
      gap: 0;

      & > * {
        width: calc(50% - 16px);
        margin: 8px;
      }

      .availability,
      .school-type,
      .school-wrap > .locations:nth-child(3) {
        width: 100%;
      }
    }

    .roles-item, .sectors {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .roles, .industries {
        min-height: 200px;
        padding: 8px 16px;
      }
    }

    .roles-wrapper .role-button {
      font-size: 14px;
      padding: 4px 8px;
    }

    label, input {
      font-size: 14px;
    }

    .styled-radio-buttons {
      width: 100%;

      .styled-radio-button {
        height: 32px;
      }
    }

    .form-item label {
      margin-bottom: 6px;
    }

    .skills-item {
      padding-bottom: 24px;
    }

    .row {
      margin-bottom: 8px;
    }
  }
}
