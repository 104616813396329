.search-results-item-wrapper {
    &.selected {
        background-color: #f4faff;
    }

    .activity-part {
        opacity: 0;
    }

    &:hover .activity-part {
        opacity: 1;
    }

    padding-top: 25px;
    padding-bottom: 26px;
    cursor: pointer;

    &:first-child {
        padding-top: 15px;

        .customCheckbox {
            margin-top: 12px;
        }

        .profile-display {
            margin-top: 1px;
        }
    }


    h1 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;
        margin: 0;
        margin-left: 8px;
    }

    h2 {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: #979797;
    }

    h3 {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: #000000;
    }

    h4 {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 240px;
    }

    h5 {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #000000;
    }

    h6 {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #979797;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 240px;
    }

    .content-wrapper {
        display: flex;
        margin-left: 21px;
        width: 95%;
    }

    .customCheckbox {
        margin-top: 12px;

        input[type="checkbox"] {
            width: 18px;
            height: 18px;
        }

        input[type="checkbox"]:before {
            width: 10px;
            height: 5px;
            top: 6px;
        }

        input[type="checkbox"]:after {
            width: 18px;
            height: 18px;
        }
    }

    .content {
        margin-left: 17px;
        position: relative;

        .profile-display {
            margin-top: 3px;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .default-pic-frame {
                width: 40px;
                height: 40px;
                font-size: var(--size-4);
            }

            .default-image-component {

                &:hover + .interview-tooltip {
                    display: block !important;
                }
            }
            .interview-tooltip {
                width: 720px;
                position: fixed;
                display: none;
                border: 1px solid #d8d8d8;
                padding: 10px;
                right: 10px;
                background-color: #fff;
                z-index: 9999;
                font-size: var(--size-4);

                h2 {
                    font-size: var(--size-5);
                    font-weight: 600;
                    margin-bottom: 20px;
                }
            }
          
        }

        .row1 {
            display: grid;
            grid-template-columns: 443px auto;
            margin-left: 5px;

            .left-part {
                display: flex;
                position: relative;
            }

            .name {
                margin-top: -1px;
                position: relative;

                a {
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #000000;

                }

            }

            .city {
                margin-left: 8px;
                margin-top: 6px;
            }

            .time {
                display: flex;
                margin-left: 20px;
                margin-top: 8px;

                .time-label {
                    margin-top: -2px;
                    margin-left: 6px;
                }
            }

            .availability {
                margin-left: 16px;
                margin-top: 3px;

                .green,
                .yellow,
                .red {
                    &:before {
                        font-size: 14px;
                    }
                }
            }

            .stars {
                display: flex;
                margin-top: 5px;
                margin-left: 11px;

                path {
                    stroke: #979797;
                    fill: #ffd800;
                }

                h2 {
                    margin-left: 4px;
                }
            }

            .right-part {
                display: grid;
                grid-template-columns: 178px 54px 42px auto;

                .activity-part {
                    display: grid;
                    grid-template-columns: 34px 34px 35px 35px 40px;
                    transition: all 0.1s ease-out;

                    .remove {
                        margin-top: -1px;                     
                    }
                }

                svg {
                    margin-top: 5.5px;

                    #a {
                        fill: black;
                    }

                    #b {
                        stroke: black;
                    }
                }

                .add-to-matches {
                    svg {
                        margin-top: 6px;
                    }
                }

                .experience-icon {
                    svg {
                        width: 16px;
                        height: 14px;
                    }
                }

                .send-to-suggest {
                    margin-top: -3px;

                    svg {
                        width: 16px;
                        height: 17px;
                    }
                }

                .add-note-icon {
                    svg {
                        margin-top: 6px;
                    }
                }

                .projects-icon {
                    margin-top: -1px;

                    .dropdown-with-sub-directories {
                        padding-top: 0;
                        padding-bottom: 0;

                        .hidden-menu {
                            width: max-content;
                            min-width: 95px;
                            top: 35px;
                            left: -15px;

                            .hidden-menu-item {
                                padding-left: 10px;
                                text-align: start;
                            }
                        }

                        .arrow {
                            top: 45%;
                        }
                    }
                }

                .mail-icon {
                    margin-top: 1px;

                    svg {
                        width: 16px;
                        height: 12px;

                        #a {
                            fill: black;
                        }

                        #b {
                            stroke: black;
                        }
                    }
                }

                .more-menu {
                    position: relative;
                    cursor: pointer;
                    margin-top: 2px;

                    .action-item-more-menu {
                        position: absolute;
                        top: -10px;
                        right: -5px;

                        .hidden-menu {
                            .hidden-menu-item {
                                margin: 0;
                                padding-left: 5px;
                                padding-top: 9.5px;
                                padding-bottom: 9.5px;

                                svg {
                                    margin-top: 0;
                                    justify-self: center;
                                }
                            }

                            .add-to-job {
                                svg {
                                    width: 21px;
                                    height: 21px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .inner-row {
            display: flex;
            margin-left: 5px;
        }

        .row2 {
            margin-left: 13px;
            margin-top: 7px;
            display: grid;
            grid-template-columns: 160px 166px 123px auto;

            .experience {
                h5 {
                    margin-left: 11px;
                }

                svg {
                    width: 16px;
                    height: 12px;
                }
            }

            .seniority {
                h5 {
                    margin-left: 6px;
                }
            }

            .salary {
                h5 {
                    margin-left: 8px;
                }
            }

            .industry {
                h5 {
                    margin-left: 6px;
                }
            }
        }

        .experience-list,
        .education-list {
            margin-top: 7px;

            svg {
                margin-left: 1px;
                width: 16px;
            }

            button {
                margin-left: -1px;
            }

            .degree-type,
            .role {
                &.gray {
                    font-weight: 500;
                    color: #707070;
                }
            }

            .company-name,
            .institute-name {
                margin-left: 5px;
                font-weight: normal;

                &.gray {
                    color: #707070;
                }
            }
        }

        .experience-list {
            margin-top: 8px;

            button {
                &.arrow {
                    margin-top: 3px;

                    &:before,
                    &:after {
                        top: 12px;
                    }

                    &.hidden {
                        visibility: hidden;
                    }

                    &.show {
                        visibility: visible;
                    }
                }
            }

            .experience-icon {
                svg {
                    margin-top: 7px;
                }
            }

            .experience-item {
                margin-left: 8px;
                margin-top: 7px;

                .company-city {
                    margin-top: 2px;
                    margin-left: 5px;
                    align-self: center;
                }

                .date {
                    align-self: center;
                    margin-top: 2px;
                    margin-left: 11px;
                    color: #707070;
                }
            }
        }

        .education-list {

            
            button {
                &.arrow {
                    margin-top: 7px;

                    &.hidden {
                        visibility: hidden;
                    }

                    &.show {
                        visibility: visible;
                    }
                }

                &:before,
                &:after {
                    top: 4px;
                }
            }

            .education-icon {
                margin-top: 3px;

                svg {
                    width: 17px;
                }
            }

            .education-item {
                margin-left: 10px;
                margin-top: 2px;

                .degree-name {
                    margin-left: 5px;

                    &.gray {
                        color: #707070;
                        font-weight: normal;
                    }
                }

                .degree-city {
                    margin-top: 0;
                    margin-left: 5px;
                }
            }
        }

        .skills {
            margin-top: 22px;
            margin-right: 56px;

            .skill-tag {
                display: inline-block;
                padding: 4px 9.35px;
                margin-right: 7px;
                margin-bottom: 10px;
                height: 24px;
                width: fit-content;
                border-radius: 12px;
                border: solid 1px #41a3fb;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.42;
                font-size: 12px;
                background-color: white;
                color: #41a3fb;

                &.mandatory {
                    color: white;
                    background-color: #41a3fb;
                }
            }
        }

        .recruiter {
            margin-top: -7px;
            justify-content: flex-end;
            margin-right: -7px;

            h2 {
                padding-top: 4px;
            }

            img {
                margin-left: 8px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-top: 2px;
            }

            .default-pic-frame {
                margin-left: 8px;
                width: 20px;
                height: 20px;
                font-size: 10px;
            }
        }

        .activities-counter {
            display: grid;
            margin-left: 2px;
            grid-template-columns: 54px 40px 53px 60px 50px 50px auto;
            position: relative;
            svg {
                margin-top: 4px;
            }

            label {
                margin-left: 3px;
                margin-top: 3px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.42;
                letter-spacing: normal;
                color: #000000;
            }

            .send-to-suggestion-icon {
                margin-top: -2px;
            }

            .send-to-suggestion-label {
                margin-left: 8px;
            }

            .mail-icon {
                margin-top: 1px;

                svg {
                    width: 16px;
                    height: 12px;

                    #a {
                        fill: #007dff;
                    }

                    #b {
                        stroke: #007dff;
                    }
                }
            }

            .mail-label {
                margin-left: 8px;
            }

            .project-label {
                margin-left: 8px;
            }

            .add-to-matches-label {
                margin-left: 9px;
            }

           
            .notes-tooltip {
                position: absolute;
                display: none;
                border: 1px solid #d8d8d8;
                padding: 10px;
                top: 25px;
                right: -74px;
                background-color: #fff;
                z-index: 9999;
                font-size: 16px;
                font-weight: 400;
            } 

            .notes-counter {
                &:hover + .notes-tooltip{
                    display: block !important;
                }
            }

        }

        .update-details {
            display: grid;
            grid-template-columns: 400px auto;
            margin-top: -3px;

            .last-update {
                display: flex;
                justify-content: flex-end;
                margin-top: 3px;
                text-align-last: right;

                .last-update-label {
                    margin-right: 4px;
                }

                .separator {
                    margin-right: 5px;
                }
            }
        }
    }
}
