html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background: var(--color-3);
  padding: 0;
  margin: 0;
  font-family: 'ploni', sans-serif !important;
  font-size: var(--body-font-size);
  color: var(--color-18);
  height: 100vh;
  overflow: auto;

  a {
    text-decoration: none;
    color: var(--color-21);
    cursor: pointer;
  
    &:hover {
      text-decoration: none;
    }
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  p {
    margin: 0;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

h1 {
  color: var(--color-18);
  font-size: var(--size-7);
  font-weight: 600;

  &.main-title-small {
    font-size: var(--size-5);
    color: var(--color-14);
    margin: 60px 0 28px;
  }
}

p {
  line-height: 1;
  color: var(--color-18);
}

.body-modal {
  overflow: hidden;
  margin-right: 15px;

  .menu-right {
    margin-right: 7px;
  }
}

:focus {
  outline: none;
}
