.text-body {
  color: var(--Mid-Grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.text-body-medium {
  color: var(--Mid-Grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.text-label {
  color: var(--Grey, #A4A4A4);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
