.select-select-box {
  .default-pic-frame, img {
    width: 24px;
    height: 24px;
    font-size: 12px;
    margin-left: 4px;
    border-radius: 50%;
    vertical-align: middle;
  }

  .lds-dual-ring:after {
    border-color: #fff transparent #fff transparent;
    width: 26px;
    height: 26px;
  }

  .modern-select-visible-part {
    border: solid 1px #979797;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 34px auto 41px;
    height: 37px;
    align-items: center;
    border-radius: 20px;
  }

  .modern-select-visible-part-name, .modern-select-visible-part-counter {
    font-size: 16px;
  }

  .modern-select-visible-part-name {
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .modern-select-visible-part-counter {
    color: #007dff;
    margin-left: 6px;
  }

  .modern-select-visible-part:before, .modern-select-visible-part:after {
    height: 9px;
    right: 20px;
    top: 42%;
    border-right: 2px solid;
  }

  .modern-select-visible-part:after {
    right: 15px;
  }

  .modern-select-list {
    width: 190px;
    left: -1px;
    margin-top: 3px;
    border-radius: 4px;
    padding-right: 40px;
    margin-right: 51px;
    height: 466px;

    .custom-scroll {
      width: 190px;
      height: 439px;
      overflow: auto;
      padding-right: 10px;
      position: relative;
      right: 10px;
      margin-top: 4px;
    }
  }

  .modern-select-list-item {
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    cursor: pointer;
    padding: 0 0 0 18px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
  }

  .modern-select-unvisible-part-name {
    text-align: left;
    text-transform: capitalize;
  }

  &.min-select-kkl {
    position: relative;

    .modern-select-visible-part-name {
      display: none;
    }

    .modern-select-visible-part {
      border: none;
      position: absolute;
      left: -79px;
      grid-template-columns: 34px auto;

      &:before, &:after {
        border-right: 2px solid;
        height: 9px;
        top: 41%;
        right: 16px;
      }

      &:after {
        right: 11px;
      }
    }

    .modern-select-list {
      width: 187px;
      top: 34px;
      left: -77px;
    }

    .modern-select-unvisible-part-name {
      color: #000;
      font-weight: 300;
      line-height: 1.28581;
    }
  }
}
