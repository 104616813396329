.modern-select {
  position: relative;
  font-size: 14px;

  .modern-select-visible-part {
    width: fit-content;
    position: relative;
    cursor: pointer;
    padding-right: 24px;


    font-size: 14px;

    .title {
      font-weight: bold;
      margin-right: 8px;
    }

    &:before, &:after {
      border-right: 1px solid;
      content: '';
      display: block;
      height: 5px;
      margin-top: -1px;
      position: absolute;
      transform: rotate(315deg);
      top: 44%;
      width: 0;
      right: 14px;
    }

    &:after {
      margin-top: -1px;
      transform: rotate(-315deg);
      right: 11px;
    }

  }

  .modern-select-list {
    position: absolute;
    background: #fff;
    left: -4px;
    padding-top: 9px;
    z-index: 5;
  }

  .modern-select-list-item {
    font-size: 14px;
    text-align: center;
    margin-bottom: 14px;
    cursor: pointer;
  }
  
  &.disabled{
    .modern-select-visible-part{
      color: #707070;
    }
  }
}
