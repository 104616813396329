@import '../../../styles/mixins.scss';

.new-message-view {
  margin-top: 97px;

  .new-message-view-wrapper {
    background: #fff;
    border: 1px solid #979797;
    border-radius: 4px;
    padding: 18px 21px;
  }

  .editor, .rdw-editor-main {
    height: 425px;
  }

  .DraftEditor-root {
    height: auto;
  }
}

.inbox-view {
  margin-top: 92px;

  .overviewWrapper {
    background: #fff;
    border-bottom-left-radius: 0;
    height: 767px;
    overflow-y: initial;

    .overviewContainer  {
      border-top-right-radius: 20px;
      box-shadow: none;
      border: none;
      display: flex;
      flex-direction: column;
      height: 754px;
    }
  }

  .profile-overview {
    box-shadow: none;
    border: none;
    display: flex;
    flex-direction: column;
    height: 754px;
  }

  .profile-info-wrapper, .company-info-wrapper  {
    overflow: auto;
    flex-grow: 1;
    padding: 0 8px;
  }

  .inbox-view-wrapper {
    padding: 0 16px;
    margin-top: 16px;
    max-width: 1540px;

    @include mobile {
      padding: 0 8px 16px;
    }

    p {
      font-family: 'ploni';
    }

    a {
      text-decoration: none;
      color: #1a99ff;
    }
  }

  .inbox-message-list {
    background: #f8f8f8;

    .inbox-messages-list {
      height: 100%;
    }
  }

  .inbox-conversations-list {
    background: #fff;
  }

  .inbox-conversations-list-empty {
    margin-top: 209px;
    text-align: center;

    svg {
      margin-bottom: 28px;
    }

    p {
      font-weight: bold;
      line-height: 1.1;
    }
  }

  .inbox-conversation-status-menu-item {
    font-size: 14px;
    height: 85%;
    display: grid;
    align-content: center;
    width: 100%;
    justify-content: center;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    margin-top: 8px;

    &.selected {
      color: #007dff;
      font-weight: bold;
      border-color: #007dff;

    }

    .sendcv-icon {
      width: 20px;
      height: 20px;
    }
  }

  .inbox-messages-list-conversation-list-empty {
    text-align: center;
    //margin-top: 341px;
    font-weight: bold;
  }

  .inbox-messages-list-conversation-list-empty-header {
    height: 58px;
    background: #e5e5e5;
    border-bottom: 1px solid #b5b5b5;
    margin-bottom: 211px;
  }

  .inbox-messages-list-conversation-list-empty-body {
    color: #707070;
    font-weight: normal;
  }

  .inbox-messages-item-body-message-attachments-list {
    display: grid;
    justify-items: right;
    align-items: center;
  }

  .inbox-messages-item-body-message-content-attachments {
    //margin-right: 18px;
    text-align: right;
    margin-bottom: 30px;

    @include mobile {
      margin-bottom: 8px;
    }
  }

  .inbox-messages-item-body-message-content-attachment {
    background: #e5e5e5;
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &.not-handled {
      color: #f75245;
      border: 1px solid;
    }
  }

  .inbox-aside-body {
    background: #f9f9f9;
    height: 660px;
    overflow: auto;
    border-bottom-left-radius: 20px;
  }

  // inbox-view-grid
  // - conversations-inbox
  // --- inbox-aside
  // --- inbox-message-list
  // - profile-edit-display-wrapper

  .inbox-view-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-top: 15px;
    background: #f9f9f9;
    height: 769px;

    @include mobile {
      display: flex;
      overflow: hidden;

      .profile-edit-display-wrapper {
        display: none;

        .styled-radio-buttons {
          grid-template-columns: repeat(auto-fit, 60px) !important;
        }

        .profile-overview {
          padding: 4px;
        }

        .show-info-wrapper > a {
          pointer-events: none;
          label { color: #000 }
        }

        .profile-overview-edit-mode {
          height: 700px;
          border-radius: 20px;
        } 

        .profile-edit-display-wrapper-action-buttons {
          top: auto;
          bottom: 0;
          z-index: 1;
          padding: 16px;
        }

        .edit-item {
          right: 20px;
        }

      }
      
      .back-icon {
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 16px;
      }

      .action-item.action-item-more-menu {
        display: none;
      }

      .conversations-inbox {
        width: 100%;
        display: flex;
        border-right: none;
        transition: transform 250ms ease-in-out;
        border-radius: 20px;

        &.main {
          transform: translateX(-100%)
        }

        &.list {
          transform: translateX(0)
        }

        &.profile {
          display: none;
        }

        &.profile + .profile-edit-display-wrapper {
          display: block;
          width: 100%;
          border-radius: 20px;
        }
      }

      .inbox-message-list {
        width: 100%;
        flex-shrink: 0;
        border-radius: 20px;

        .inbox-new-message {
          overflow: auto;

          .header-navigation-profile {
            display: none;
          }

          .dal-body {
            padding: 8px 16px;
          }

          .email-top-sms {
            display: flex;
            flex-direction: column;
            padding-bottom: 4px;
          }

          .sms-body textarea {
            height: 350px;
          }
        }

        .inbox-messages-item-attachment-body-header-title {
          text-align: left;
          padding-left: 16px;
        }

        .new-message-template-attachments {
          display: flex;
          flex-direction: column;
          gap: 8px;
          position: relative;

          .file-upload-forms {
            justify-self: right;
            padding-right: 8px;
          }
        }

        .inbox-messages-item {
          height: 100%;
          display: flex;
          flex-direction: column;

          .inbox-messages-item-body-wrapper {
            height: auto;
            overflow: auto;
            flex-grow: 1;
          }

          .inbox-messages-item-body-message-profile {
            padding-top: 16px;
            padding-bottom: 16px;
          }

          .inbox-messages-item-reply-part {
            flex-grow: 0;
          }

          &.input-opened .rdw-editor-main {
            height: 180px;
          }
        }
      }

      .inbox-messages-reply, 
      .inbox-messages-reply-disabled, 
      .inbox-aside-body  {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .inbox-aside {
        width: 100%;
        flex-shrink: 0;
        border-right: none; 
      }
    }
  }

  .inbox-view-wrapper-top-out {
    height: 69px;
    display: grid;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .inbox-view-wrapper-top {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 10px;
    max-width: 1540px;
    padding: 0 16px;

    @include mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;

      .new-message-link {
        padding: 0;
        min-width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        & > .text { display: none; }
        & > svg { display: inline-block; }
      }
    }
  }

  .conversations-inbox {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 365px 1fr;
    border-right: 1px solid #b5b5b5;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .new-message-link {
    height: 40px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: normal;
    border-radius: 25px;

    & > svg {
      display: none;
    }
  }

  .inbox-aside {
    border-right: 1px solid #b5b5b5;
  }

  .inbox-conversation-sort,
  .inbox-conversation-filter {

    .dropdown-with-sub-directories {

      grid-template-columns: 22% max-content max-content;
      padding: 0;
      height: 100%;
      align-items: center;
      align-self: center;
      margin-left: 15px;

      &:before {
        content: "";
        width: 0;
        height: 21.8px;
        border: solid 1px #d8d8d8;
        margin-top: 5px;
        align-self: center;
        margin-left: 5px;
      }

      .arrow {
        margin-top: 15px;
      }

      label {
        display: grid;
        margin-top: 3px;
      }

      .filter-icon {
        margin-left: 3px;
        height: 100%;
        margin-right: 3px;

        svg {
          display: grid;
          margin-top: unset;
          height: 100%;
          margin-top: 2px;
        } 

        &.selected {
          svg {
            g {
              path {
                stroke: #3edf83;
              }
            }
    
          } 
        }

      }

      .hidden-menu {

        width: 110px;
        left: 5px;
        padding-bottom: 5px;


        .hidden-menu-item  {

          .label-selected {
            color: #3edf83;
          }

          .with-sub-directory {
            display: grid;
            grid-template-columns: 35px 50px auto;     
            align-items: center;
            text-align: start;
            height: 30px;

            svg {
              justify-self: right;
              margin-right: 8px;
            }

            .sub-directory {
              position: absolute;
              right: -110px;
              top: -2px;
              left: auto;
            }

            .tag-selected {

              path {
                stroke:#3edf83;
              }
            }
            
            .close {
              left: 2px;
            }

            &.source, &.type ,&.category {

              svg {
                visibility: hidden;
              }
            }
          }


          .item-class-wrapper {
            width: 98px;
            display: grid;
            grid-template-columns: 35px 50px;
            align-items: center;
            text-align: start;
            height: 30px;

            &.starred {

              svg {
                path {
                    stroke:#707070;
                }
              }

              .starred-selected {
                  path {
                      stroke:#3edf83;
                  }
              }
            }

            &.attachment {

              svg {
                width: 15px;
              }
            }

            .mail4-selected {

              g {
                path {
                  stroke:#3edf83;
                }

                g {
                  g {
                    path {
                      fill: none;
                    }
                  }
                }
              }
            }


  
            svg {
              justify-self: right;
              margin-right: 8px;
            }
          }

        }
        
      }

    }

  }

  .inbox-conversation-filter {

    .dropdown-with-sub-directories {

      &:before {
        content: "";
        width: 0;
        height: 21.8px;
        border: solid 1px #d8d8d8;
        margin-top: 5px;
        align-self: center;
        margin-left: 5px;
      }

      .filter-icon {
        margin-left: 5px;
      }

      @include mobile {
        .hidden-menu {
          left: auto;
          right: 0;

          &.sub-directory {
            right: 110px !important;
            max-height: 400px;
            overflow: auto;
          }
        }
      }
    }
  }

  .inbox-conversation-sort {

    .dropdown-with-sub-directories {

      &:before {
        content: "";
        width: 0;
        height: 21.8px;
        border: solid 1px #d8d8d8;
        margin-top: 5px;
        align-self: center;
        margin-left: -5px;
      }


      .filter-icon {

        svg {

          width: 13px;
  
          g {
            path {
              fill: black;
            }
          }
        }
      }
    }
  }

  .inbox-aside-header-actions {
    display: grid;
    grid-template-columns: 51% 13% 13% auto;
    border-bottom: 1px solid #b5b5b5;
    height: 47px;
    background: #f8f8f8;
    position: relative;
  }

  .inbox-conversation-status-menu {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
  }

  .border-3 {
    border: solid 1px #b5b5b5;
  }

  .inbox-menu-providers {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 58px;
    border-bottom: 1px solid #b5b5b5;
    background: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @include mobile {
      grid-template-columns: repeat(4, 1fr);

      .inbox-menu-provider.chat {
        display: none;
      }
    }
  }

  .inbox-menu-provider-text {
    font-size: 9px;
  }

  .inbox-menu-provider {
    font-weight: bold;
    position: relative;
    cursor: pointer;
    grid-template-rows: auto 15px;
    justify-items: center;
    height: 58px;
    display: grid;
    align-content: end;
    grid-gap: 4px;
    border-bottom: 3px solid transparent;

    &.selected {
      color: #007dff;
      border-color: #007dff;
    }
  }

  .inbox-menu-provider-unread {
    position: absolute;
    width: 13px;
    height: 13px;
    font-size: 9px;
    color: #fff;
    background: #0e7ada;
    border-radius: 50%;
    text-align: center;
    top: 15px;
    right: 31.6%;
    line-height: 1.4;
  }

  .inbox-conversations-search-visible-part {
    margin: 15px 0 0 0;
  }

  .inbox-conversations-search {

    display: grid;
    grid-template-columns: 65% auto;

    &.open {
      z-index: 20;
    }

    &:before {
      content: "";
      width: 0;
      height: 21.8px;
      border: solid 1px #d8d8d8;
      margin-top: 5px;
      align-self: center;
      margin-left: 5px;
    }

    .inbox-conversations-search-visible-part {
      margin-left: -10px;
    }

    svg {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }

    .inbox-search-icon-svg {
      width: 13px;
      position: absolute;
      top: 13px;
      right: 13px;
    }

    .form-item {
      position: absolute;
      top: 6px;
      right: 5px;
      width: 97.2%;

      input {
        padding-left: 9px;
        font-size: 14px;
        padding-right: 85px;
      }
    }

    .filter-inbox-by-search {
      font-size: 12px;
      position: absolute;
      right: 31px;
      top: 14px;
      padding: 4px 8px;
      min-width: auto;
      border-radius: 10px;
    }
  }

  .inbox-conversations-item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 17px;
    align-items: center;
    min-height: 84px;
    position: relative;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #b5b5b5;
    }

    &:hover, &.selected {
      background: #d6edff;

      .inbox-conversations-item-tag::after {
        border-right: 4px solid #d6edff;
      }
    }

    &:hover {
      .inbox-conversations-item-message-actions {
        display: grid;
      }

      h3 {
        padding-right: 70px;
      }
    }

    &.unread {
      .inbox-conversations-item-profile-info {
        h3, .inbox-conversations-item-message-preview {
          font-weight: bold;
          color: #000;

          &.blocked-messages {
            color: red;
          }
        }
      }
    }

    &.sms {
      .inbox-conversations-item-profile-info .bottom {
        margin-top: 20px;
      }
    }
  }

  .inbox-conversations-item-profile-pic {
    width: 44px;
    height: 44px;
    margin-left: 13px;

    img, svg, .default-pic-frame {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      font-size: 19px;
    }
  }

  .inbox-conversations-item-profile-info {
    height: 100%;

    h3 {
      font-size: 16px;
      font-weight: normal;
      margin-top: 14px;
      width: 282px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.blocked-messages {
        color: red;
      }
    }

    .name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 112px;
      display: inline-block;
      vertical-align: top;
    }

    .time {
      font-size: 12px;
      font-weight: normal;
      position: relative;
      margin-right: 26px;
      text-align: right;
      color: #979797;
      margin-top: 4px;
    }

    .bottom {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-top: 10px;
    }
  }

  .inbox-conversations-item-info {
    p, svg {
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
    }
  }

  .inbox-conversations-item-message-preview {
    font-size: 14px;
    color: #707070;
    margin-top: 2px;
    width: 282px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .inbox-conversations-source {
    display: inline-block;
    vertical-align: top;
    font-size: 11px;
    text-decoration: underline;
    margin-left: 10px;
  }

  .inbox-conversations-item-tag {
    font-size: 10px;
    color: #000;
    position: relative;
    display: block;
    width: fit-content;
    margin-left: 1px;
    height: fit-content;
    padding: 2px 12px 2px 8px;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 1px;
      right: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 4px solid #f9f9f9;
    }

    &.green {
      background: rgba(51, 193, 94, 0.35);
    }

    &.none {
      background: #e5e5e5;
    }

    &.gray {
      background: #d8d8d8;
    }

    &.red {
      background: rgba(247, 82, 69, 0.35);
    }
  }

  .inbox-messages-header, .inbox-messages-item-header {

    h3 {
      font-size: 18px;
      color: #02074f;
      padding: 10px 0 0 11px;
    }
  }

  .inbox-messages-header-bottom {
    //height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .inbox-messages-header-tag {
    font-size: 12px;
    color: #fff;
    justify-self: end;
    margin: 1px 9px 0 0;
    padding: 0 5px;
    border: 1px solid #fff;
    border-radius: 4px;
  }

  .inbox-messages-header-selector {
    font-size: 12px;
    color: #fff;
    justify-self: start;
    margin: 0 0 0 1px;
  }

  .inbox-messages-list-parents {
    padding: 2px 0 0 0;
  }

  .inbox-messages-list-parent {
    padding: 16px 21px;
    margin: 8px 12px 1px 10px;
    border: 1px solid #979797;
    height: 69px;
    cursor: pointer;
    position: relative;

    &.unread {

      &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: #007dff;
        border-radius: 50%;
        left: 6px;
        top: 30px;
      }
    }
  }

  .inbox-messages-list-parent-date {
    color: #949494;
    font-size: 12px;
  }

  .inbox-messages-item-header {
    position: relative;

    h3 {
      font-size: 22px;
      font-weight: normal;
      padding: 0;
      line-height: 1;
      height: 46px;
      align-content: center;
      display: grid;
      word-break: break-word;

      @include mobile {
        font-size: 16px !important;
        height: auto;
      }
    }

    .header-navigation {
      display: none;
      padding: 8px 12px;

      &-back, &-profile {
        display: flex;
        gap: 6px;
        align-items: center;
        cursor: pointer;

        span {
          font-size: 14px;
          color: var(--color-26);
        }
      }

      @include mobile {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .inbox-messages-item-header-back {
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    height: 15px;
    border-radius: 2px;
    margin: 21px 0 0 13px;
    padding: 1px 3px;
    line-height: 1;
    cursor: pointer;
  }

  .inbox-messages-item-header-info-date {
    color: #fff;
    font-size: 12px;
  }

  .inbox-messages-item-body-wrapper {
    height: 560px;
    background: #fff;

    &.expanded-ccbcc {
      height: 507px;
    }

    &.expanded-cc-1 {
      height: 551px;
    }

    &.expanded {
      height: 369px;

      .expander {
        transform: rotate(-135deg);
      }

      .rdw-editor-main {
        height: 302px;
      }

      &.expanded-ccbcc {
        height: 281px;
      }

      &.expanded-cc-1 {
        height: 325px;
      }
    }
  }

  .email-ka {

    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .inbox-messages-item-body-wrapper {
      height: 480px;
      background: #fff;

      &.expanded-ccbcc {
        height: 452px;
      }

      &.expanded-cc-1 {
        height: 496px;
      }

      &.expanded {
        height: 314px;

        .expander {
          transform: rotate(-135deg);
        }

        .rdw-editor-main {
          height: 257px;
        }

        &.expanded-ccbcc {
          height: 226px;
        }

        &.expanded-cc-1 {
          height: 270px;
        }
      }

      @include mobile {
        height: 320px;

        &.expanded-cc-1 {
          height: 300px;
        }

        &.expanded-ccbcc {
          height: 280px;
        }
      }
    }

    .inbox-messages-item-reply-part {
      flex-grow: 1;
    }
  }

  .inbox-messages-item-body {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }



  .inbox-messages-item-body-message {
    padding: 0 20px;
    margin: 9px 6px 11px 6px;
    border: 1px solid #d8d8d8;
    position: relative;
    border-radius: 4px;

    @include mobile {
      margin: 0;
      padding: 0 12px;
      border: none !important;
    }

    &.child {
      margin: 10px 0 18px 37px;

      .inbox-messages-item-body-message-profile {
        padding-top: 0;
      }
    }

    &.closed {
      padding: 35px 30px 0px;

      @include mobile {
        padding: 16px 32px 0px 16px;
      }

      .inbox-messages-item-body-message-content {
          overflow: hidden;
          max-height: 40px;
          margin-bottom: 20px;
      }

      .show-more {
        background: transparent;
        color: var(--color-2);
        font-size: 13px;
        text-decoration: underline;
        text-align: left;
        cursor: pointer;
        padding-right: 0;
        padding-left: 0;
        line-height: 1em;
      }

      .inbox-messages-item-body-message-profile {
        margin-left: 0;
      }

    }

    &.opened {
      grid-template-columns: 7% auto;
      grid-gap: 3px;

      .inbox-messages-item-body-message-profile {
        padding-top: 15px;
      }

      .inbox-messages-item-body-message-user-info {
        //grid-template-rows: 42px 16px;
      }

      .inbox-messages-item-body-message-content {
        margin: 14px 0;
      }
    }
  }

  .inbox-messages-item-body-message-selected {
    border: 1px solid #0000ff;
  }

  .inbox-messages-item-body-message-cc-bcc {
    font-size: 12px;
    color: #818181;
    margin: 9px 0 0 2px;
  }

  .inbox-messages-item-body-message-heading {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    padding: 1px 0 1px 5px;

    @include mobile {
      flex-direction: column;
      margin-top: 0;
    }
  }

  .inbox-messages-item-body-message-attachment-sign {
    position: absolute;
    right: 7px;
    top: 20px;

    svg {
      width: 18px;
      height: 17px;
    }
  }

  .inbox-messages-item-body-message-user-info {
    font-size: 14px;
    color: #979797;
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;

    strong {
      font-size: 14px;
      display: block;
      color: #000;
    }
  }

  .inbox-messages-item-body-message-profile {
    .default-pic-frame, img {
      width: 27px;
      height: 27px;
      font-size: 16px;
      border-radius: 50%;
    }
  }

  .inbox-messages-item-body-message-content {
    // display: grid;
    align-items: center;
  }

  .inbox-messages-item-body-message-profile {
    padding-top: 25px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 9px;
    margin-left: 8px;
    padding-bottom: 22px;
  }

  .inbox-messages-item-body-message-sms {
    padding: 24px 24px 0 24px;
    margin: 9px 30px 11px 20px;
    border: 1px solid #d8d8d8;
    position: relative;
    border-radius: 4px;
    max-width: 713px;

    @include mobile {
      padding: 16px 16px 0 16px;
      margin: 16px;
    }

    &.child {
      justify-items: start;

      .inbox-messages-item-body-message-content-text-sms {
        color: #000;
      }

      .inbox-messages-item-body-message-content-info-sms {
        text-align: left;
      }
    }
  }

  .inbox-messages-item-body-message-content-sms {
    margin-right: 6px;
    margin-left: 10px;
    word-wrap: break-word;
  }

  .inbox-messages-item-body-message-content-text-sms {
    //width: fit-content;
    //background: #1a99ff;
    //color: #fff;
    //padding: 12px 45px 10px 12px;

    p {
      line-height: 1.2;
      color: inherit;
    }
  }

  .inbox-messages-item-body-message-content-info-sms {
    text-align: right;
    font-size: 12px;
    color: #707070;
    margin-top: 4px;
  }

  .inbox-messages-item-body-message-content-info-sender-sms {

  }

  .inbox-messages-item-body-message-content-text {
    font-size: 14px;
    // word-break: break-all;
    max-width: 696px;
    overflow-x: auto;

    &.preview {
      word-break: break-word;
      line-height: 1.31;
      font-size: 14px;
    }

    p {
      line-height: 1.3;
      min-height: 10px;
    }

    pre {
      white-space: pre-wrap;       /* Since CSS 2.1 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      white-space: -pre-wrap;      /* Opera 4-6 */
      white-space: -o-pre-wrap;    /* Opera 7 */
      word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }

    table {
      max-width: 100%;


      tr {
        td {
          // deleted this fix in order to show id=606d61a95af4d8001cb2c439 mail
          // white-space:nowrap;
        }

        th {
          white-space: nowrap;
          text-align: start;
          padding-left: 16px;
        }
      }

      //for ethosia style
      //for example: id=6023a603233618001cf21377
      tbody {
        tr {
          td {
            table {
              tbody {
                tr {
                  td {
                    div {
                      table {
                        tbody {
                          tr {
                            td {
                              white-space: normal;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .inbox-messages-reply-bottom-cont-wrap {
    display: grid;
    grid-template-columns: 93.1% auto;
    grid-gap: 10px;
    position: relative;

    .send-handle-wrapper {
      justify-content: end;
      display: flex;
      flex-direction: column;
  
      .whatsapp-time-left {
        position: relative;
        font-size: 12.5px;
        margin-bottom: 10px;
      }   
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: end;

      .form-item > textarea {
        height: 120px;
      }
    }
  }

  .inbox-messages-reply-bottom-cont-wrap-multi-recipients-mode {
    grid-template-columns: auto;
  }

  .inbox-messages-reply .multi-recipients-mode-buttons-container {
    display: grid;
    grid-template-columns: 125px 125px 125px;
    .replay, .replay-all {
      width: 100px;
      height: auto;
      padding: 6px;

      &.disabled-opacity-less {
        pointer-events: none;
      }
    }
  }

  .inbox-messages-item-body-message-content-info-sender {
    color: #949494;
    font-size: 12px;
    margin-top: 2px;
  }

  .inbox-messages-reply {
    position: relative;
    border-top: 1px solid #b5b5b5;
    padding: 8px 20px 0 16px;
    // background: #f8f8f8;
    background: #fff;
    height: 100%;

    .inbox-messages-reply-editor {
      display: flex;
      flex-direction: column-reverse;
      
      .rdw-editor-main {
        border-color: #EEEFF2;
        padding: 0 16px;
        border-radius: 4px 4px 0 0;
      }

      .rdw-editor-toolbar {
        border-color: #EEEFF2;
        border-top: 0;
        border-radius: 0 0 4px 4px;

        .rdw-editor-item {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
        }

        .rdw-editor-upload {
          margin-right: 8px;
        }
  
        .rdw-editor-divider {
          width: 1px;
          height: 20px;
          margin: 0 8px;
          background-color: #c7c7c7;
        }

        .rdw-editor-right {
          margin-left: auto;
        }

        .rdw-link-modal {
          top: auto;
          bottom: 100%;

          .rdw-link-modal-target-option {
            display: flex;
            align-items: center;
          }
        }

        .new-message-super-search-input-fancy-cc-bcc {
          position: static;
        }
      }
    }

    @include mobile {
      padding: 8px 12px;

      .expander {
        display: none;
      }

      .multi-recipients-mode-buttons-container {
        display: flex;
        justify-content: space-between;

        & > button {
          width: 30%;
        }
      }

      .form-item > input {
        height: 30px;
        padding: 0 8px;
      }

      .inbox-messages-reply-attachment-preview-list {
        display: flex;
        justify-content: flex-end;
        gap: 4px;
      }

      .inbox-messages-reply-bottom-cont-wrap-multi-recipients-mode {
        height: 100%;
        display: flex;
        gap: 16px;
        flex-direction: column;

        .inbox-messages-reply-editor-area {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .inbox-messages-reply-editor {
          flex: 1;

          .rdw-editor-main {
            height: 120px;
            padding: 0 16px;
          }
        }
      }
    }

    .rdw-editor-main, textarea {
      background: #fff;
      height: 72px;
      font-size: 14px;
      border: solid 1px #b5b5b5;
    }

    textarea {
      min-height: 72px;
      line-height: initial;
    }

    footer {
      margin-top: 19px;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      grid-gap: 22px;
      position: relative;
    }

    button:not(.epr-btn, .bs-menu-button, .rdw-link-modal-btn, .bp3-button) {
      font-size: 16px;
      height: 42px;
      display: grid;
      align-self: end;
      width: 45px;
      text-align: center;

      &.loading {
        svg {
          display: none;
        }
      }
    }

    .cc-bcc {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &.expanded {

      .rdw-editor-main, textarea {
        height: 270px;
      }

      .expander {
        transform: rotate(-135deg);
        right: 7px;
        top: 13px;
      }

    }


  }

  .inbox-messages-reply-disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(142, 141, 141, 0.3);
  }

  .expander {
    right: 1px;
    top: 6px;
  }

  .inbox-messages-list-parent-attachment-icon {
    position: absolute;
    right: 8px;
    top: 15px;
  }

  .inbox-messages-reply-attachment {
    margin-left: 6px;
    display: flex;
    cursor: pointer;
  }

  .inbox-messages-item-header-actions {
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.new-message {
      grid-template-columns: auto;
      justify-items: end;
    }

    @include mobile {
      display: none;

      &.new-message {
        display: flex;
      }
    }
  }

  .inbox-messages-item-header-action {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hr {
      width: 1px;
      background: #b5b5b5;
      margin: 0 11px;
    }

    &.handled {

      &.importance {
        path {
          fill: #f75245
        }
      }

      &.isStared {
        path {
          fill: #ffd800
        }
      }
    }

    &.talat {
      svg {
        height: 15px;
        width: 15px;
      }
    }
  }

  .modern-select-list {
    width: 140px;
    margin-top: 4px;
  }

  .inbox-messages-assigment-select .modern-select-list {
    width: 190px;
  }

  .inbox-conversations-item-message-attachment-indicator {
    position: absolute;
    top: 15px;
    right: 18px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .inbox-messages-item-attachment-body {
    height: 790px;

    &.expanded-ccbcc {
      height: 507px;
    }

    &.expanded-cc-1 {
      height: 551px;
    }

    &.expanded {
      height: 369px;

      .expander {
        transform: rotate(-135deg);
      }

      .rdw-editor-main {
        height: 302px;
      }

      &.expanded-ccbcc {
        height: 281px;
      }

      &.expanded-cc-1 {
        height: 325px;
      }
    }
  }

  .inbox-messages-item-attachment-body-img {

    img {
      width: 100%;
    }
  }

  .inbox-messages-reply-header {
    // display: grid;
    // grid-template-columns: 24% auto 18%;
    // grid-gap: 20px;
    // margin-bottom: 9px;
    // align-items: baseline;

    display: flex;
    gap: 16px;
    align-items: flex-start;
    padding-bottom: 10px;

    .select-template {
      font-size: 14px;
    }

    @include mobile {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 12px;

      // .bs-menu-button {
      //   min-width: 200px;
      // }

      .form-item.select-template > select {
        height: 30px;
      }

      .inbox-messages-reply-header-predefined-messages {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .new-message-attachments-smiles-cc-bcc {
    .new-message-super-search-input-fancy-cc-bcc {
      position: inherit;
      right: inherit;
      top: inherit;
    }
  }

  .inbox-messages-item-attachment-body-header-title {
    text-align: center;
    height: 100%;
    display: grid;
    align-content: center;
    font-size: 12px;
    margin-top: 4px;
  }

  .new-message-attachments-smiles {
    display: flex;
    align-items: center;
    gap: 8px;

    margin-left: auto;
    height: 40px;

    .fancy-switch-button {
        width: 135px;
        .onoffswitch-label {
        height: 20px;
        line-height: 20px;
        border-radius: 20px;
      }
    }

    @include mobile {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .inbox-messages-item-attachment-body-header-close, .inbox-messages-item-attachment-body-header-action {
    cursor: pointer;
  }

  .inbox-messages-item-attachment-body-header-actions {
    position: absolute;
    right: 18px;
    top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1.7fr;
    align-items: center;
    justify-items: end;
    grid-gap: 8px;

    svg {
      width: 21px;
    }
  }

  .inbox-messages-item-attachment-body-header-action-download {
    font-size: 22px;

    a {
      color: #000;
    }

  }

  .inbox-messages-item-attachment-body-header {
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    position: relative;
    height: 55px;
  }

  .modern-select-list-search-input {
    border-radius: 0;
    margin: 3px 0 0 11px;
    width: 153px;
    height: 33px;
  }

  .inbox-messages-item-header-info {
    padding: 0 13px 0 22px;
    height: 47px;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;

    @include mobile {
      padding: 8px 16px;
      height: auto;
      min-height: 40px;

      display: flex;
      align-items: center;
    }
  }

  .inbox-messages-header-bottom-actions {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 0 23px;
    border-bottom: 1px solid #f5f5f5;
    height: 56px;
    align-items: center;
    background: #fff;
    margin-top: 3px;

    &.new-message {
      grid-template-columns: 12% 40.4% 22% 22% auto
    }

    .inbox-messages-header-selector {
      max-width: 35%;
    }

    @include mobile {
      height: auto;
      padding: 8px 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      &.new-message {
        grid-template-columns: none;
        grid-template-areas:
          'type assigned assigned'
          'label tag flag';

        .conversation-type-selector {
          grid-area: type;
        }
        .inbox-messages-header-selector {
          grid-area: assigned;
        }
        .inbox-messages-header-selector-labels  {
          grid-area: label;
        }
        .inbox-messages-header-selector-tags  {
          grid-area: tag;
        }
        .inbox-messages-item-header-actions.new-message {
          grid-area: flag;
        }
      }

      .inbox-messages-header-selector-labels, .inbox-messages-item-header-actions {
        margin-left: auto
      }

      &:not(.new-message) .inbox-messages-header-selector-labels .modern-select-list {
        right: 0;
        left: auto;
      }

      .inbox-messages-header-selector {
        max-width: none;
      }
    }
  }

  .conversation-type-selector {
    .modern-select-list {
      width: 65px;
    }
  }

  .modern-select-visible-part {
    color: #000;
    font-size: 12px;
  }

  .inbox-messages-item {

    .DraftEditor-root {
      height: auto;
    }

    &.input-opened {
      .inbox-messages-item-body-wrapper {
        height: 202px;
      }

      .inbox-messages-item-body {
        height: 202px;
      }

      .inbox-messages-reply {
        .rdw-editor-main {
          height: 406px;
        }

      }
    }

  }

  .sms-body textarea {
    height: 498px;
  }

  .profile-overview-edit-mode {
    height: 750px;
    overflow: auto;
    border: none;
    box-shadow: none;

    .input-wrapper.more-details {
      .gender {
        margin-left: 12px;
      }
    }
  }

  .processes-menu-actions {
    position: absolute;
    right: 12px;
    font-size: 13px;
    top: 37px;
    color: #1a99ff;
    z-index: 1;
    cursor: default;

    .processes-menu-actions-visible {
      position: absolute;
      border-radius: 3px;
      background: #fff;
      display: none;
      font-size: 15px;
      color: #000;
      padding: 9px 11px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      width: 160px;
    }

    &:hover {
      .processes-menu-actions-visible {
        display: block;
      }
    }

    .processes-menu-actions-visible-item {
      margin: 13px 0;
      cursor: pointer;
    }

  }

  .inbox-messages-item-body-message-user-info-info {

  }

  .inbox-messages-item-body-message-user-info-status {
    display: grid;
    justify-content: end;


    .inbox-messages-item-body-message-user-info-status-p {
      position: relative;

      &::after {
        content: '✓';
        position: absolute;
        font-size: 12px;
        left: -2px;
        top: 2px;
        color: #b5b5b5;
      }

      &.delivered {

      }

      &.open, &.read {
        &::after {
          color: #007dff;
        }
      }

      &.spamreport {
        &::after {
          content: '!';
          color: #fff;
          width: 10px;
          height: 10px;
          background: #f16147;
          border-radius: 50%;
          padding: 0 4px;
          font-size: 9px;
          left: -3px;
          top: 3px;
        }
      }

      &.dropped, &.bounce, &.deferred, &.failed, &.undelivered {
        &::after {
          content: '✖';
          color: #f16147;
          left: -3px;
          top: 3px;
        }
      }
    }
  }

  .inbox-conversations-item-message-actions {
    position: absolute;
    top: 15px;
    right: 40px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    display: none;

    .inbox-conversations-item-message-actions-item {
      border: 1px solid transparent;
      height: 19px;

      &:hover {
        border: 1px solid #4ca8f1;
      }
    }
  }

  .inbox-action-mobile {
    display: none;
    
    @include mobile {
      display: block;
      text-align: right;
    }
  }
}

.inbox-action-popover {
  .bp3-menu-item {
    padding: 8px 12px;

    &.active {
      &.importance {
        path {
          fill: #f75245
        }
      }

      &.isStared {
        path {
          fill: #ffd800
        }
      }
    }
  }
}
