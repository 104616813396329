.back-link {
  display: block;
  position: relative;
  background: var(--color-8);
  text-align: center;
  margin: 19px 0;
  padding: 11px 0 12px 9px;
  border-radius: 3px;
  line-height: 1;
  width: 79px;
  font-weight: 600;
  border: 1px solid transparent;
  color: var(--color-18);
  cursor: pointer;
  outline: none;

  &:hover {
    border: 1px solid;
  }

  &:after, &:before {
    border-right: 2px solid;
    content: '';
    display: block;
    height: 8px;
    margin-top: -6px;
    position: absolute;
    transform: rotate(45deg);
    left: 10px;
    top: 50%;
    width: 0;
  }

  &:after {
    margin-top: -1px;
    transform: rotate(135deg);
  }
}
