.profile-status {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: end;
    align-items: center;
    justify-content: flex-end;
    margin-top: 7px;


    .dropdown-with-sub-directories {
      border: solid 1px #979797;
      padding: 0px 0px;
      border-radius: 4px;
      color: #000000;
      display: grid;
      grid-template-columns: 25px 90px auto;

      h2 {
        font-size: 16px;
        color: #000000;
        margin-right: 0px;
        text-align: center;
      }

      .arrow {
        margin-top: 12px;
      }
    }

    .status {
      position: relative;
      margin-left: 10px;

      &:before {
        content: "•";
        color: var(--color-7);
      }

      &.available {
        &:before {
          color: var(--color-19);
        }
      }

      &.notAvailable {
        &:before {
          color: var(--color-7);
        }
      }

    }
    

  }