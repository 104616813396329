.conversation-type-selector {

  .modern-select-visible-part {
    border: 1px solid #b5b5b5;
    padding: 0 33px 0 11px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: grid;
    align-content: center;
    height: 36px;

    &::after {
      height: 9px;
      right: 14px;
    }

    &::before {
      height: 9px;
      top: 45%;
      right: 20px;
    }
  }

  .modern-select-list{
    left: 0;
    top: 34px;
    border: 1px solid #b5b5b5;
    width: 65px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .modern-select-list-item{
    margin-bottom: 9px;
    margin-left: 9px;
    width: 25px;
  }
}
