.signup-loader {
  position: relative;
  bottom: 180px;
}

.signup {

  position: relative;
  text-align: center;

  display: grid;
  grid-template-rows: 40px 52px 60px 85px 82px 103px 60px;

  .signup-email {
    //border: none;
    display: inline-block;
    width: 100%;
  }

  .disabled-button {
    cursor: initial;
  }

  .right-side {
    text-align: left !important;
  }

  span {
    font-family: 'ploni';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .error {
    display:inline-block;
    width: 100%;
    text-align: right;
    font-family: 'ploni';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #f75245;    
  }
  .error-border {
    border: solid 1px #f75245;
  }

  input {
    height: 36px;
    border-radius: 4px;
    border: solid 1px rgba(112, 112, 112, 0.25);
    background-color: #ffffff;
  }

  .lbl {
    text-align: left;
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;  
    font-family: 'ploni';
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;    
  }

  .name {
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    grid-column-gap: 16px;
  }
  .password-container {
    position: relative;
    width: 100%;

    .password {
      padding: 0 33px 0 8px;
    }

    .password-icon {
      position: absolute;
      margin: 14px 6px;
      top: 0;
      right: 0;
    }
  }

  .password-hint-container {
    display: grid;
    grid-template-columns: calc(50% + 8px) auto;
    font-family: 'ploni';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;

    .hint-error {
      color: #f75245;
    }

    .hint-pass-ok {
      color: #d8d8d8;
      text-decoration: line-through;
    }

    .hint-left {
      display: inline-block;
      text-align: left;
    }
    .hint-right {
      display: inline-block;
      text-align: left;
    }
  }

  button {
    width: 100%;
    height: 52px;
    border-radius: 4px;
    border: solid 1px #f8f8f8;
    background-color: #000000;

    font-family: 'ploni';
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;    
  }

}

.auth-invalid-link {
  // padding: 48px 90px 280px 90px !important;

  .signup-invalid-link {
    
    .invalid-link-container {
      text-align: center;
      margin-top: 209px;
    }
    .internal-server-error-container {
      text-align: center;
      margin-top: 209px;
      .retry-button{
        margin-top: 25px;
        width: 146px;
        height: 52px;
        border-radius: 4px;
        border: solid 1px #f8f8f8;
        background-color: #000000;
      }
    }

    .invalid-link-header {
      font-family: 'ploni';
      font-size: 28px;
      height: 33px;
      color: #000000;
    }
    .invalid-link-body {
      font-family: 'ploni';
      font-size: 16px;
      height: 19px;
      color: #000000;
      margin-top: 10px;
    }
  }
}

.internal-server-error {
  // padding: 163px 90px 205px 90px !important;
  
  .signup-internal-server-error {
    .internal-server-error-container {
      text-align: center;
      
      .internal-server-error-icon {
        margin-bottom: 36px;
      }

      .retry-button{
        margin-top: 25px;
        width: 146px;
        height: 52px;
        border-radius: 4px;
        border: solid 1px #f8f8f8;
        background-color: #000000;
      }

      .internal-server-error-header {
        font-family: 'ploni';
        font-size: 28px;
        height: 33px;
        color: #000000;
      }
      .internal-server-error-body {
        font-family: 'ploni';
        font-size: 16px;
        height: 19px;
        color: #000000;
        margin-top: 10px;
      }
    }
  }
}