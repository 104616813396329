.data-table {

  .table-title {
    background: var(--table-hd-bg-color);
    color: var(--button-color);
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    > div {
      //border-right: solid 1px var(--rare-background-color);
      padding: 15px 24px;
      word-spacing: 2px;
      line-height: 2.5;

      &.menu-container, &.search-wrapper {
        padding: 14px;
      }

      &.menu-container {
        .context-menu {
          top: 55px;
          right: 5px;
        }

        .fa {
          margin-left: -7px;
          font-size: var(--paragraph-font-size);
        }
      }
    }
    input {
      float: right;
      background: var(--paragraph-color);
      border: none;
      color: var(--input-background-color);
      padding: 5px;
      width: 158px;
      height: 16px;
      margin-top: 7px;
    }

    .circle {
      vertical-align: middle;
      margin-top: 0;

      .fa-calendar {
        margin-left: -6px;
      }

      .fa-ellipsis-h {
        font-size: var(--paragraph-m-font-size);
      }

      .fa-filter {
        margin-left: -6px;
        margin-top: -5px;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;

    body {
      color: var(--input-background-color);
    }

    img {
      width: 50px;
      border-radius: 50%;
    }
  }

  th {
    cursor: pointer;
    background: var(--table-heading-bg-color);
    color: var(--color-8);
    font-weight: normal;
    height: 67px;
    text-align: left;
    font-size: var(--table-heading-font-size);

    &:not(.table-th-nohover):hover {
      background: var(--table-heading-bg-color-hover);
    }

  }

  tr {

    th:first-child, td:first-child {
      width: 56px;
      text-align: center;
      padding: 0;
      margin: 0;
    }

    td:first-child {
      width: 23px;
      vertical-align: inherit;

      input {
        top: -5px;
        left: -7px;
      }
    }

    td {
      font-size: var(--paragraph-font-size);
      color: var(--input-background-color);
      padding: 19px 0;
      border-bottom: solid 1px var(--link-color);
      vertical-align: top;

      p {
        padding: 0;
        margin: 4px 0 0 0;
        color: var(--input-background-color);
      }

      a {

        strong {
          color: var(--text-color);
          font-weight: 600;
          font-size: var(--paragraph-font-size);
        }

        &:hover {
          strong {
            color: var(--table-row-bg-color-hover);
          }
        }
      }

      .customCheckbox {
        position: relative;

        input {
          position: absolute;
          top: 26px;
          left: 17px !important;
        }
      }

    }
  }
}
