.customCheckbox {
  input {
    position: relative;
    width: inherit;
    height: inherit;
  }
  input[type='checkbox']:after {
    line-height: 1.5em;
    content: '';
    display: inline-block;
    //margin-top: -3px;
    //margin-left: -1px;
    border: 1px solid var(--heading-color);
    height: 20px;
    width: 20px;
    background-color: var(--color-8);
    cursor: pointer;
    white-space: nowrap;
    padding: 0;
    position: relative;
    font-weight: 400;
    border-radius: 2px;
  }

  input[type='checkbox']:checked:after {
    background: #fff;
    height: 20px;
    width: 20px;
    display: inline-block;
    padding: 0 0 0 0;
    border: 1px solid var(--color-2);
    color: var(--color-2);
  }

  input[type='checkbox']:checked:before {
    content: '';
    opacity: 1;
    position: absolute;
    width: 12px;
    height: 7px;
    background: transparent;
    top: 4px;
    left: 4px;
    border: 2px solid var(--color-2);
    border-top: none;
    border-right: none;
    display: block;
    transform: rotate(-45deg);
    z-index: 1;
  }

}

