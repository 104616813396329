@import '../../../styles/mixins.scss';

.auth {
  width: 629px;
  margin: 117px auto 0 auto;
  background: #fff;
  position: relative;
  padding: 48px 120px 120px;
  min-height: 538px;

  .welcome-back {
    text-align: center;
    color: var(--color-1);
    font-size: var(--size-6);
    font-weight: 400;
    line-height: 28px;
  }

  .login-icon-wrapper {
    width: 100%;
    text-align: center;

    svg {
      width: 126px;
      height: 35px;
    }
  }

  &.login {

    h1 {
      color: var(--color-1);
      font-size: var(--size-7);
      font-weight: 600;
      line-height: 28px;
      margin: 41px 0 26px 0;
    }

    .button {
      width: 100%;
      font-size: var(--size-5);
      height: 49px;
    }

    .submit {
      margin-top: 80px;
      margin-bottom: 20px;
      position: relative;
    }
  }

  &.reset-password {
    h1 {
      margin: 41px 0 7px 0;
    }

    p {
      line-height: 1.3;
      font-size: var(--size-4);
    }

    .form {
      margin-top: 24px;

      .reset-email {
        margin-top: 24px;
      }

      .submit {
        margin-top: 59px;
        margin-bottom: 138px;
      }
    }

    .success-reset {
      text-align: center;

      svg {
        margin-top: 138px;
      }

      .success {
        font-size: var(--size-7);
        font-weight: 600;
        padding-top: 22px;
        padding-bottom: 8px;
      }

      .sub-text {
        font-size: var(--size-4);
        margin-bottom: 144px;
        text-align: center;
      }

      a {
        font-size: 12px;
        color: var(--color-1);
      }
    }
  }

  @include responsive-until($md) {
    max-width: calc(100% - 40px);
    padding: 40px 80px 80px;
    margin-top: 80px;

    &.login h1 {
      font-size: 24px;
    }
  }

  @include mobile {
    max-width: calc(100% - 16px);
    padding: 40px 60px 60px;
    margin-top: 60px;

    &.login h1 {
      font-size: 20px;
    }
  }
}

.reset {
  a {
    font-size: 12px;
    color: var(--color-1);
  }
}

.logging {
  text-align: center;
  font-size: 36px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login-message {
  text-align: center;
  line-height: 1.5;
  padding: 30px 0;

  &.error {
    color: var(--color-7);
    font-size: var(--size-2);
    position: absolute;
    left: 0;
    top: -28px;
    line-height: 1;
    padding: 0;
    width: 100%;
    text-align: center;
  }
}

.login-set-password {

  .message {
    text-align: center;
  }
}

.invalid-token {
  text-align: center;
}

.login-back-link {
  font-size: 12px;
  color: var(--color-1);
  display: block;
  text-align: center;
}

.reset-password {
  .error {
    top: -50px;
  }
}
