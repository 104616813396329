.stars-component {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 38%;

  &.editable {
    .star-rating {
      cursor: pointer;

      &:hover {
        g {
          fill: #1a99ff;
        }
      }
    }
  }

  .filled {

    &.not-touch {
      g {
        fill: red;
        stroke: red
      }
    }

    g {
      fill: #02074F
    }
  }
}

.ranking{

  .stars-component{
    margin-top: 6px;
    width: 30%;
  }
}
