@import '../../../styles/mixins.scss';

.inbox-messaging-component {
  //display: grid;
  //grid-template-rows: 90% auto;
  //height: 742px;

  .form-item {
    font-size: 14px;
    margin: 10px 0;
  }

  .blue-tabs-buttons {
    .tabs {
      grid-template-columns: 83px 83px;
      display: grid;
      justify-content: center;
      width: 166px;
      margin: 0 auto;
    }

    .tab {
      height: 37px;
      text-align: center;
      width: 83px;
      justify-self: end;
      color: #000;
      cursor: pointer;
      border: solid 1px rgba(112, 112, 112, 0.25);
      display: grid;
      align-content: center;
    }

    .sms {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .whatsapp {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .email {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    .selected {
      background: #1a99ff;
      color: #fff;
      border: solid 1px #1a99ff;
    }
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
  }

  label {
    font-size: 16px;
    font-weight: bold;
    margin-left: 3px;
    display: block;
    margin-bottom: 6px;
  }

  .email-messaging-input-template {

  }

  .subject {
    margin: 10px 0;
  }

  .cc-bcc {
    margin: 10px 0;
  }

  footer {
    display: grid;
    grid-gap: 17px;
    justify-items: end;
    position: relative;
    padding: 3px 23px;
    grid-template-columns: 1fr auto;
    align-items: center;

    .multiple-email-container {
      display: grid;
      grid-template-columns: 100px 140px;
      height: 26px;
      align-items: center;
      .fancy-switch-button {
        height: 26px;
      }
    }

    .button {
      padding: 7px 10px;
      display: grid;
    }
  }

  .file-upload-forms {
    justify-self: left;
  }

  .email-addresses {
    min-height: 36px;
    border-radius: 4px;
    border: solid 1px rgba(112, 112, 112, 0.25);
    width: 100%;
    display: grid;
    //grid-template-columns: auto 80px;
    align-items: center;
    grid-gap: 15px;

    .email-addresses-ind {
      max-height: 184px;
      overflow: auto;
    }
  }

  .email-address {
    display: inline-block;
    font-size: 12px;
    border: 1px solid #707070;
    border-radius: 13px;
    padding: 2px 9px;
    margin: 1px 0 1px 5px;

    span {
      margin-left: 10px;
    }
  }

  .sms-body {
    margin: 24px 0 10px;

    textarea {
      height: 480px;
      line-height: initial;
    }
  }

  .sms-counter {
    font-size: 14px;

    .sms-counter-item {
      display: inline-block;
      margin-right: 15px;
      vertical-align: top;
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }
  }

  .email-top-sms {
    display: grid;
    grid-template-columns: 22% auto;
    margin: 4px 0 8px;
    grid-gap: 12px;
    align-items: baseline;

    .form-item{
      margin: 0;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
    }
  }

  .email-buttons {
    font-size: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 23px;

    .pur {
      color: #1a99ff;
    }

    div {
      cursor: pointer;
    }
  }

  &.is-modal {
    .editor, .rdw-editor-main {
      &.short {
        height: 194px;
      }
    }
  }

  .inbox-messages-reply-attachment {
    cursor: pointer;
  }

  .send-messages-attachment-data{

    .send-messages-attachment-data-item{
      background: #e5e5e5;
      border-radius: 4px;
      padding: 5px;
      font-size: 12px;

      span{
        cursor: pointer;
        margin: 7px;
      }
    }
  }
}

.new-message-super-search-input-fancy-wrapper {
  position: relative;
  margin-bottom: 8px;
}

.new-message-super-search-input-fancy-cc-bcc {
  position: absolute;
  right: 0;
  top: 7px;

  .new-message-super-search-input-fancy-cc {
    margin-right: 12px;
    cursor: pointer;
    font-size: 14px;
    color: #b5b5b5;

    &:hover, &.highlight {
      color: #000;
    }
  }
}

.dal-body {
  padding: 11px 23px;

  .email-editor {
    background: #fff;
  }

  .rdw-editor-wrapper {
    height: 494px;
  }

  .short {
    .rdw-editor-wrapper {
      height: 470px;
    }
  }

  .short-2 {
    .rdw-editor-wrapper {
      height: 425px;
    }
  }

  .select-template {
    width: 168px;
    margin: 0;
  }

  .new-message-template-attachments {
    margin-bottom: 9px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: baseline;

    @include mobile {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;
      row-gap: 8px;
      column-gap: 24px;

      .inbox-messages-reply-header-predefined-messages {
        width: 100%;
        order: 1;
      }
    }
  }

  .public-DraftStyleDefault-block {
    margin: 0;
    padding: 1em 0;
  }

  .text-area-container {
    display: grid;
    grid-template-columns: 80% auto;
  }

  .new-message-attachments-smiles {
    display: grid;
    grid-template-columns: 25px auto;
    align-self: center;
    justify-self: end;

    .multiple-email-container {
      display: grid;
      grid-template-columns: max-content auto;
    }

  }
}

.inbox-new-message {
  height: 100%;

  .new-message-header-subject {
    display: grid;
    height: 100%;

    input {
      font-size: 20px;
      border: 0;
      padding: 0 0 0 2px;
      height: auto;

      @include mobile {
        font-size: 16px;
      }
    }
  }
}
