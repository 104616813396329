.notification-item-container {
    height: 100vh;
    
    .header-container {
        width: 122px;
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 15px;

        .type {
            width: 50px;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
        .clear-list {
            cursor: pointer;
            align-self: end;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: normal;
            text-align: left;
            color: #007dff;
        }
    }

    .notifications-container {

        .lds-dual-ring {
            top: 40%;
        }

        max-height: 750px;
        overflow: auto;

        .zero-notifications {
            font-size: 32px;
            font-weight: 500;
            margin:150px 0 0 200px;
        }

        .notification-container {
            // width: 1136px;
            height: 108px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 20px 32px 14px 40px;

            display: grid;
            grid-template-columns: 72px 514px 100px 55px 85px 216px auto;
            align-items: center;

            img {
                border-radius: 50%;
            }
            &:first-child {
                border-radius: 4px 4px 0 0;
            }
            &:last-child {
                border-radius: 0 0 4px 4px;
            }

            .img, .default-pic-frame, img {
                font-size: 23px;
                width: 50px;
                height: 50px;
            }

            .message {
                display: inline-block;
                max-width: 437px;
                .display-name, .description {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000000;
                }
                .display-name {
                    font-weight: bold;
                }
                .message-text {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000000;
                }
                .message-bold {
                    font-weight: bold;
                }
                .assign-to {
                    font-weight: 900;
                    padding-top: 20px;
                    font-size: 14px;
                }
            }

            .date {
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.42;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
            }

            .close-button-container {
                cursor: pointer;
                width: 16px;
                height: 16px;
            }

            .button-container {
                cursor: pointer;
                position: relative;
                display: grid;
                align-items: center;
                grid-template-columns: auto auto;
                padding: 7px;
                width: 40px;
                height: 40px;
                border: solid 1px #000000;
                background-color: #ffffff;  
                border-radius: 50%;
                div {
                    font-size: 16px;
                    line-height: 1.44;
                    font-weight: 600;
                    text-align: center;
                    color: #000000;
                }

                &:hover {
                    background-color: #f8f8f8;
                }
            }

            .view-lbl {
                cursor: pointer;
                position: relative;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                text-align: left;
                color: #007dff;

                .two-buttons {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }
            .close-icon-container {
                display: none;
                position: relative;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                }
            }

            &:hover .close-icon-container {
                display: block;
            }

        }
    }

    .selected {
        background-color: #f8f8f8 !important;
    }
}