.notes-wrapper {
  margin-top: 26px;

  .notes-scroll {
    max-height: 370px;
    overflow-y: auto;
  }

  .note-text {
    padding-right: 10px;
    white-space: pre-line;
    word-break: break-word;
  }

  .note-item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    margin-bottom: 24px;
  }

  .img, .default-pic-frame, img {
    font-size: 23px;
    width: 40px;
    height: 40px;
  }

  .note-info {
    color: #707070;

    span {
      color: #000000;
      font-weight: bold;
    }
  }

  .notes-input {
    &:focus-within .buttons-group {
      display: block;
    }
  }

  .buttons-group {
    display: none;
    margin-top: 12px;

    .button {
      font-size: 16px;
      font-weight: bold;
      width: 86px;
      padding: 7px;
      margin-right: 10px;
    }
  }

  textarea {
    height: 42px;
    min-height: 42px;
    resize: none;
    padding: 8px 0 0 17px;
    transition: height .25s ease-out;

    &:focus {
      height: 112px;
      transition: height .25s ease-out;
    }
  }

  .have-content {

    textarea {
      height: 112px;
    }

    .buttons-group {
      display: block;
    }
  }
}
