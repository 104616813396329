@import '../../../styles/mixins.scss';

.inbox-view .bp3-button.select-recruiter-dropdown {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 24px;

  height: 40px;
  width: 250px;

  &:hover {
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  & > .bp3-icon {
    color: #404040;
    transform: rotate(0);
    transition: transform 150ms ease-in-out;
  }

  &.bp4-popover2-open > .bp3-icon {
    transform: rotate(180deg);
  }

  & > .bp3-button-text {
    @include text-overflow;
  }

  & > a.clear-all {
    padding: 0;
    color: var(--color-26);
    margin-right: 12px;

    &.bp3-disabled {
      color: #c7c7c7;
    }
  }

  .select-recruiter-circle,
  .default-image-component > img,
  .default-image-component > .default-pic-frame {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--action-text-color);
  }

  &.assigned-to {
    width: auto;
    max-width: 100%;
    padding: 0;
    box-shadow: none;

    & > *:not(:last-child) {
      font-size: 12px;
      margin-right: 8px;
    }

    @include mobile {
      max-width: 200px;
    }
  }
}

// POPOVER
.select-recruiter-popover {
  width: 360px;

  .select-recruiter-content {
    padding: 16px 20px 24px;

    .select-recruiter-header {
      display: flex;
      gap: 16px;
      align-items: center;
      padding-bottom: 8px;

      position: relative;

      .toggle-button {
        width: auto;

        .toggle-button-text {
          color: #6c6c6c;
          margin-right: 60px;
        }

        label > * {
          margin-top: 0;

          @include mobile {
            margin-top: 10px;
          }
        }
      }

      & > span {
        font-size: 20px;
        font-weight: 500;
        color: #404040;
        margin-right: auto;
      }

      .select-recruiter-actions {
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 1;

        height: 48px;
        display: flex;
        gap: 16px;
        align-items: center;

        & > a {
          padding: 0;
          font-size: 14px;
          color: #6c6c6c;
          font-weight: 500;
          border-radius: 0;
          border-bottom: 1px solid #6c6c6c;
  
          &:hover {
            color: var(--color-26);
            border-color: var(--color-26);
          }
  
          &.bp3-disabled {
            color: #c7c7c7;
            border-color: #c7c7c7;
          }
        }
      }
    }

    .select-recruiter-loading .lds-dual-ring {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 150px;
      padding-bottom: 150px;
    }

    .bp3-tab-panel {
      margin-top: 24px;
    }

    .bp3-tab-list > *:not(:last-child) {
      margin-right: 24px;
    }

    .bp3-tab {
      line-height: 48px;
      color: #6c6c6c;
      font-size: 18px;
      font-weight: 500;

      &[aria-selected='true'] {
        color: #2f80ed;
        font-weight: 600;
      }
    }

    .bp3-tab-indicator {
      height: 5px;
      background-color: #307ff6;
    }

    .select-recruiter-bottom {
      padding-top: 24px;
      border-top: 1px solid #eeeff2;

      .bp3-button {
        background: #307ff6;
        border-radius: 60px;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        min-height: 40px;
      }
    }
  }

  // SEARCH
  .select-recruiter-search {
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeff2;

    .bp3-input {
      border: 1px solid #eeeff2;
      box-shadow: none;
      height: 40px;
      padding-left: 16px;
      border-radius: 12px;
      font-size: 16px;
      text-transform: none;
    }

    .bp3-input-action {
      height: 40px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .bp3-icon-cross {
        cursor: pointer;
        margin-right: 8px;
      }
    }
  }

  .select-recruiter-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 100px 24px;
    color: #404040;

    & > span {
      @include text-overflow(100%);
    }

    & > svg {
      color: #6f7e8c;
    }
  }

  .bp3-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    border-radius: 0;
    margin-top: 16px;
    margin-bottom: 24px;

    height: 350px;
    overflow-y: auto;
    padding-right: 8px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f8f8f8;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c7c7c7;
      border-radius: 10px;
    }
  }

  .bp3-menu-item {
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    border-radius: 8px;
    color: #404040;

    &.selected {
      color: #307ff6;
    }

    .bp3-menu-item-label {
      color: #6c6c6c;
      font-weight: 400;
    }

    .menu-item-left {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-right: 0;
    }

    .bp3-control {
      margin: 0;

      .bp3-control-indicator {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #6c6c6c;
        box-shadow: none !important;
        background-color: #f8f8f8 !important;
      }

      input:checked ~ .bp3-control-indicator {
        border-color: #307ff6;

        &::before {
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.666626 4.66667L3.36838 8.33333L7.99996 1' stroke='%23307FF6' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
    }

    .default-image-component {
      display: flex;
      position: relative;
      margin-right: 10px;

      & > img,
      & > .default-pic-frame {
        width: 40px;
        height: 40px;
        font-size: 18px;
        border-radius: 50%;
      }

      &.online-status::before,
      &.offline-status::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #3edf83;
      }

      &.offline-status::before {
        background: #ff0000;
      }
    }
  }

  @include mobile {
    min-width: calc(100vw - 16px);

    .select-recruiter-content {
      padding: 8px 20px 12px;

      .select-recruiter-header {
        padding-bottom: 0;
        & > span {
          font-size: 18px;
        }
        & > button {
          font-size: 14px;
        }
      }

      .bp3-tab {
        font-size: 16px;
        line-height: 40px;
      }

      .bp3-tab-panel {
        margin-top: 16px;
      }

      .select-recruiter-bottom {
        padding-top: 16px;
      }
    }

    .bp3-menu {
      height: calc(100vh - 400px);
      height: calc(100dvh - 400px);
      margin-top: 8px;
      margin-bottom: 8px;

      .bp3-menu-item {
        padding: 8px 12px;
      }
    }
  }

  &.assigned-to {
    width: 300px;

    @include mobile {
      min-width: 0;
    }

    .select-recruiter-content {
      padding: 12px;
    }

    .bp3-tab-panel {
      margin-top: 0;
    }

    .bp3-menu {
      margin-top: 8px;
      margin-bottom: 8px;
      height: 280px;
    }

    .bp3-menu-item {
      font-size: 14px;
      padding: 8px 12px;
    }

    .select-recruiter-bottom {
      padding-top: 16px;

      .bp3-button {
        min-height: 36px;
        font-size: 14px;
      }
    }

    .select-recruiter-search {
      .bp3-input,
      .bp3-input-action {
        height: 36px;
        font-size: 14px;
      }

      .bp3-input {
        border-radius: 8px;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .default-image-component {
      & > img,
      & > .default-pic-frame {
        width: 32px;
        height: 32px;
        font-size: 16px;
      }

      &.online-status::before,
      &.offline-status::before {
        width: 8px;
        height: 8px;
      }
    }
  }
}
