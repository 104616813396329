@import '../../../styles/mixins.scss';

.rec-navigation-bar {
  display: flex;
  align-items: center;
  padding: 0 16px;

  &.max-width{
    max-width: 1536px;
  }

  a.logo {
    display: flex;
    svg {
        margin-top: -2px;
        margin-left: 0px;
    }
  }
  
  .profile-pic {
    margin-top: 32px;

    &:hover {
      cursor: pointer;
    }

    img, .default-pic-frame {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 20px;
    }
  }
  
  .navigation-menu {
    flex: 1;
    display: flex;
    gap: 40px;
    position: relative;
    margin-left: 44px;

    @include responsive-until($xxl) {
      gap: 32px;
      margin-left: 36px;
    }

    a {
      font-family: Ploni DL 1.1 AAA;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      
      color: #00074F;
      text-decoration: none;
      display: inline-block;

      &:active, &.active {
        opacity: 1;
        color: #307FF6;
      }
  
      &:hover {
        opacity: 1;
      }
    }
  }
  
  .icon-menu {
    color: var(--color-8);
    font-size: 25px;
  
    > a > .fa {
      margin: 0 12px;
      cursor: pointer;
      color: var(--color-8);
      vertical-align: super;
  
      &:hover {
        color: var(--link-color-selected);
  
        .fa {
          color: var(--link-color-selected);
        }
      }
  
      .fa {
        font-size: var(--paragraph-font-size);
  
        &.red {
          color: var(--link-color-selected);
          margin-top: -15px;
          margin-left: -10px;
        }
      }
    }
  
    .menu-container {
      vertical-align: sub;
      padding-bottom: 21px;
  
      .context-menu {
        top: 56px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 20px;

        .menu-header {
          display: grid;
          grid-template-columns: 50% 50%;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid var(--color-9);
          padding: 4px 0 2px 14px;
          margin: 1px;
          cursor: pointer;
          line-height: 3;
        }

        .menu-item {
          border-bottom: 1px solid #D5D5D5;
          padding: 5px 0 2px 15px;
          margin: 1px 0;
          cursor: pointer;
          line-height: 3;
          font-family: Ploni DL 1.1 AAA;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #00074F;

          &:first-child {
            &:hover {
              border-radius: 20px 20px 0 0;
            }
          }
          &:last-child {
            border-bottom: none;
            &:hover {
              border-radius: 0 0 18px 18px;
              height: 50px;
            }
          }
  
          &:hover {
  
            background: #F7F7F7;
  
            // a {
            //   color: var(--color-8);
            // }
          }
  
          a {
            color: #00074F;
            margin: 0;
          }

          &.status {

            display: grid;
            grid-template-columns: 0.2fr  1fr;

            label {
              color: #00074F;
              font-weight: 400;
            }

            .fancy-switch-button2 {

              .text {
                margin-left: 10px;
              }
            }
          }
        }
      }
  
      &.left {
        .context-menu {
          top: 55px;
          left: -4px;
          right: inherit;
  
  
          &:before {
            left: 15px;
            top: -10px;
            right: inherit;
          }
        }
      }
  
      // div {
      //   &:nth-child(2) {
      //     position: relative;
      //     bottom: 1px;
      //     left: 9px;
      //   }
      // }
  
    }

    .add-menu {
      .context-menu {
        min-width: 149px;
      }
    }

  }
  
  .menu-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 20px;
  
    .bk-white-less {
      margin: 0 0 0 3px;
      height: 28px;
    }
  
    .bell-icon {
      display: flex;
      position: relative;
      cursor: pointer;

      .tot-notification-bell {
        position: absolute;
        top: -5px;
        left: 5px;
        font-size: 9px;
        width: 13px;
        border-radius: 50%;
        background-color: #f75245;
        color: #02074f;
        text-align: center;
      }
    }
    .profile-img {
      position: relative;
    }
    
    .invite {
      margin-left: 17px;
    }
    .add-round-button {
      cursor: pointer;
      border: 1px solid #fff;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      position: relative;
      top: 11px;
      
      &:after,
      &:before {
        content: "";
        display: block;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      
      &:before {
        height: 11px;
        width: 1px;
      }
    
      &:after {
        height: 1px;
        width: 11px;
      }    
    }
  
    .add-round-button2 {
      cursor: pointer;
      position: relative;
      line-height: normal;
      top: 15px;
    }

    .icons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 18px;
      align-items: center;
      margin-top: 3px;
    }
  
    .chat-notification {
      margin-left: 3px;
    }
  
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 11px;
  
      .button {
        min-width: 69px;
      }
    }
  
    .image-cropper {
      margin-top: 16px;
  
      svg, .default-pic-frame {
        height: 40px;
        width: 40px;
      }
  
      .default-pic-frame {
        cursor: pointer;
        font-size: var(--size-6);
      }
    }
  }
  
  .recruiter {
  
    .navigation-bar {
      grid-template-columns: auto 2.7fr 2fr auto;
    }
  }

  a.notifications, a.search {
    display: none;
  }

  // screen < 1200px
  @include responsive-until($xl) {
    .navigation-menu {
      position: fixed;
      width: 100%;
      height: calc(100% - 92px);
      top: 92px;
      left: 0;
      margin: 0;
      gap: 0;

      display: flex;
      flex-direction: column;
      background-color: var(--color-21);
      transition: all 300ms ease-in-out;

      opacity: 0;
      visibility: hidden;
      transform: translateX(-100%);

      &.show {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }

      & > a {
        font-size: 20px;
        line-height: 60px;
        margin: 0 !important;
        padding: 0 16px !important;
        color: white;

        &:not(:last-child) {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  // screen < 768px
    @include responsive-until($md) {
      button.search-recruiter-toggle {
        display: inline-flex;
        border-radius: 50%;
        background-color: #307FF6;
        transition: all 150ms ease-in-out;
        margin-right: 4px;
  
        .bp3-icon {
          color: #fff;
        }

        &:hover {
          background-color: #307FF6;
        }
  
        &.show {
          background-color: #00074F;
        }
      }
  
      .navigation-menu > a.search {
        display: inline-block;
      }

      a.logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .menu-right {
        gap: 16px;
      }

      .menu-right .search-recruiter-collapse {
        position: fixed;
        top: 92px;
        left: 0;
        right: 0;
        z-index: -1;
      }

      .search.recruiter {
        height: calc(100vh - 92px);
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-top: 1px solid #EEEFF2;

        .search-results {
          position: static;
          width: 100%;
          border-radius: 12px;
        }

        .search-results-section legend {
          margin: 8px 12px 4px;
        }

        .search-results-section .black-link {
          padding: 10px 16px;
        }
      }
    }
  
    // screen < 576px
    @include mobile {
      .menu-right .bell-icon {
        display: none;
      }
  
      // .profile-img .menu-item:not(.status, .log-out, .profile) {
      //   display: none;
      // }
  
      .navigation-menu > a:not(.conversations, .jobs, .companies, .candidates) {
        display: none;
      }

      .nav-icon {
        margin-right: 16px;
      }
  
      a.logo {
        width: 140px;
      }

      // .icon-menu.add-icon {
      //   position: absolute;
      //   left: 56px;
      //   top: 50%;
      //   transform: translateY(-50%);

      //   .context-menu {
      //     left: 0;
      //     right: auto;
      //   }
      // }

      button.search-recruiter-toggle {
        margin-right: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(56px, -50%);
      }

      // .add-round-button2 {
      //   path, circle {
      //     stroke: #307FF6;
      //     transition: all 150ms ease-in-out;
      //   }

      //   &:hover path, &:hover circle {
      //     stroke: #00074F;
      //   }
      // }

      .search.recruiter {
        gap: 70px;
      }
    }

    // screen > 767px
    @include responsive-from($md) {
      .menu-right .search-recruiter-collapse {
        height: auto;
        overflow-y: visible;

        .bp3-collapse-body {
            display: block;
            transform: none !important;
        }
      }
    }
}
