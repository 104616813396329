.send-cv-component {
  //margin-top: -65px;
  //margin-bottom: 100px;

  .multi-with-cc-bcc {
    position: relative;
  }

  .cc-bcc-wrapper {
    position: absolute;
    right: 9px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    top: 35px;
  }

  .cc-bcc-item {
    cursor: pointer;
  }

  .cc-bcc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 35px;
    margin: 20px 0;
  }

  .rdw-editor-wrapper {
    margin: 10px 0;
  }

  .rdw-editor-main {
    min-height: 110px;
  }

  .select-messaging-template-component {
    width: max-content;

    select {
      padding-right: 10px;
    }
  }

}
