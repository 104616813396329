.tabs-module {

  .tabs {

    .tab {

      min-width: 85px;

      &.tab_0 {
        min-height: 72px;
        vertical-align: top;
        padding-top: 20px;

        .title::before {
          margin-top: 20px;
          padding-top: 20px;
        }
      }
      &.tab_4 {
        border-right: none;
      }
      &.tab_5 {
        border-right: solid 1px var(--link-color);
      }
      &.tab_6 {
        right: 170px;
      }
      &.tab_7 {
        right: 85px;
      }
      &.tab_8 {
        border: solid 1px var(--link-color);
      }
    }
  }
}

.applicants {
  .grid-table {
    header {

      ul {
        grid-template-columns: 18% 43% 12% 11% 16%;

        .applicant-grid-header-candidate {
          margin-left: 13px;
          cursor: pointer;
          position: relative;

          .text {
            display: inline-block;
            white-space: nowrap;
            padding: 0 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 170px;
            vertical-align: top;
          }

          svg {
            margin: 0 0 2px 9px;
          }

          .candidate-for-filter {
            top: 25px;
            left: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            .hidden-menu-item {
              color: var(--color-1);
              font-weight: 300;
              white-space: nowrap;
              padding: 0 10px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .applicant-grid-header-talent {
          margin-left: 7px;
        }

        .applicant-grid-header-status, .applicant-grid-header-fit {
          margin-left: 9px;
        }

        .applicant-grid-header-update {
          margin-left: 22px;
        }

        .applicant-grid-header-rejected-all {
          margin-left: 18px;
          cursor: pointer;
          position: relative;

          svg {
            margin: 0 0 2px 9px;
          }

          .rejected-filter {
            top: 25px;
            left: -1px;
            right: inherit;
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            .hidden-menu-item {
              color: var(--color-1);
              font-weight: 300;
            }
          }
        }
      }
    }

    &.rejected-tab {
      header {

        ul {
          grid-template-columns: 25% 41% 14% 20%;
        }
      }
    }

  }

  .grid-body {

    .virtualized-grid-list {
      outline: none;
    }

    .grid-body-item {
      display: grid;
      grid-template-columns: 17% 44% 10% 13% 16%;
      border-bottom: 1px solid var(--color-9);
      padding-bottom: 9px;

      @media (max-width: 875px) {
        grid-template-columns: 18% 42% 6% 34%;
      }

      .job-title {
        font-size: var(--size-4);
        margin: 20px 0 0 12px;
        @media (max-width: 1044px) {
          margin: 25px 0 0 15px;
        }
        @media (max-width: 875px) {
          margin: 25px 0 0 4px;
        }

        .process-status {
          margin-top: 13px;

          legend {
            font-size: var(--size-2);
            color: var(--color-1);
            font-weight: 300;
            margin-bottom: 3px;
          }

          p {
            font-weight: 600;
            font-size: var(--size-5);
            color: var(--color-2);
          }
        }
      }

      .update {
        margin-left: 22px;
      }

      .talent {
        margin: 19px 0 0 8px;
        display: grid;
        grid-template-columns: 0.8fr 6fr;
        grid-gap: 1px;

        &.not-exposed {
          //img, .default-pic-frame {
          //  filter: blur(4px);
          //}
        }

        img, .default-pic-frame {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          font-size: var(--size-6);

          &.anon {
            svg {
              width: 22px;
            }
          }
        }
      }

      .status {
        margin: 22px 0 0 11px;

        strong {
          font-size: var(--size-4);
        }
      }

      .fit {
        margin-top: 23px;
        text-align: center;

        .progress-bar {
          width: 84px;
          text-align: center;
          margin: 0 0 3px 32px;
        }

        strong {
          font-size: var(--size-314);
        }
      }

      .talent-fit {
        font-weight: 600;
        font-size: var(--size-5);
        margin: 3px 0 0 2px;

        span {
          font-size: var(--size-1);
          margin-left: 2px;
        }
      }

      .talent-info {

        p {
          margin: 2px 0 4px 0;
          color: var(--color-18);
          font-size: var(--size-4);

          svg {
            margin: 0 4px 0 0;
          }

          .capitalized {
            color: var(--color-2);
          }
        }

        legend {
          //margin: 16px 0 3px;
          color: var(--color-18);
          font-weight: 600;
          font-size: var(--size-314);
        }

        .talent-info-grid {
          display: grid;
          grid-template-columns: 1fr 5fr;
          grid-gap: 3px 6px;
          margin-top: 17px;
          align-items: start;

          p {
            font-size: var(--size-314);
            color: var(--color-4);
          }

          .skills {

            .skill-tag {
              font-size: var(--size-2);
            }
          }
        }
      }

      .process-actions {
        .process-actions-body {
          @media (max-width: 1044px) {
            margin-right: 25px;
          }
        }
      }
    }
  }

  .single-page {
    header {

      ul {
        grid-template-columns: 58% 11% 15% 16%;

        .applicant-grid-header-talent {
          margin-left: 16px;
        }
      }
    }

    .grid-body {
      border: none;

      .grid-body-item {
        grid-template-columns: 54% 15% 14% 17%;

        .talent {
          margin: 22px 0 0 4px;
          grid-gap: 8px;
        }

        .status {
          margin: 24px 0 0 21px;

          .sub-status {
            font-size: 12px;
          }
        }

        .update {
          margin-left: 30px;
        }

        .process-actions-body {
          margin-right: 8px;
        }

        .talent-fit {
          text-align: center;

          span {
            margin-left: 0;
          }
        }

        .fit {
          .progress-bar {
            margin: 0 auto 3px auto;
          }
        }

        &:last-child{
          border-bottom: 1px solid var(--color-9);
        }
      }
    }
  }

  .recruiter {
    header {

      ul {
        grid-template-columns: 43.6% 17.5% 8.9% 8.5% 10% 11.4%;

        .applicant-grid-header-talent {
          margin-left: 13px;
        }
      }
    }

    .grid-body {

      .grid-body-item {
        grid-template-columns: 43.6% 17.3% 8.1% 7.3% 12% 11.5%;

        .talent {

          margin-left: 11px;
        }

        .company {
          margin-top: 22px;
        }
      }
    }
  }

  .applicant-grid-wrapper {
    margin-top: 11px;
  }

  .leads-pending-status {
    font-size: 12px;
    border-radius: 2px;
    //background-color: #707070;
    //color: #fff;
    //font-weight: bold;
    //text-align: center;
    margin: 5px 0 0 0;
  }
}

.company-view {

  .recruiter-company-tabs-top {
    .tab-body {
      padding: 0 0 30px 0;

      .company-info {
        margin-bottom: -30px;
      }
    }

    .tabs {

      .tab {

        &.tab_6 {
          position: initial;
          margin-right: 25px;
          min-width: initial;
        }
      
        &.tab_7 {
          position: initial;
          margin-right: 25px;
        }
      }
    }

  }

  .company-applicants {

    .round-tabs{
      margin: 0 4px;
    }

    .tabs {
      text-align: left;

      .tab {
        padding: 11px 0 0 17px;
        margin: 3px 0 2px 0;
        border: solid 1px var(--color-12);
        border-right: none;
        opacity: 1;
        min-width: 100px;

        &.selected {
          .title {
            border: none;
          }
        }


        .tabs-body {
          border: solid 1px var(--link-color);
          background: var(--color-8)
        }

        &.tab_4 {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-right: solid 1px var(--color-12);

          &:before, &:after {
            content: none;
          }
        }
      }
    }

    .recruiter {
      @extend .tabs-module;

      .tabs {

        .tab {
        
          &.tab_0 {
            min-height: 67px;
          }
        }
      }
    }

    .grid-body {
      .grid-body-item {
        grid-template-columns: 36% 19% 13% 16% auto;
      }
    }

    header ul {
      grid-template-columns: 35% 23% 12% 15% auto;
    }

  }
}

.single-job {
  .blue-tabs > .tab-container > .tabs {
    padding: 0 0 4px;
    // margin: 25px 8px 0 10px;
    margin: 25px 0 0 0;
    border-bottom: 1px solid #b5b5b5;

    .tab {
      &:first-child {
        padding-left: 10px;
      }
      &:last-child {
        padding-right: 8px;
      }

      margin-right: 3px;
      color: #000;
      opacity: 0.5;
      font-size: 14px;
      font-weight: 900;
      margin-left: 3px;
      min-width: inherit;
      margin-right: 26px;

      &.selected {
        opacity: 1;
        color: var(--color-2);
      }
    }
  }
  

  .blue-tabs {

    .tabs {

      .header {
        .title {
          position: relative;
          padding: 0 0 12px;
        }
      }
    }

    .round-tabs {
      .tabs {
        text-align: left;

        .tab {
          padding: 11px 0 0 17px;
          margin: 3px 0 2px 0;
          border: solid 1px var(--color-12);
          border-right: none;
          opacity: 1;

          &.selected {
            .title {
              border: none;
            }
          }


          .tabs-body {
            border: solid 1px var(--link-color);
            background: var(--color-8)
          }

          &.tab_4 {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-right: solid 1px var(--color-12);

            &:before, &:after {
              content: none;
            }
          }

          &.tab_5 {
            position: absolute;
            border-right: solid 1px var(--link-color);
            right: 0 !important;
          }
        }
      }
    }

  }

  .tab-body {
    padding: 0 8px;
    margin-top: 5px;
  }
  .recruiter {
    @extend .tabs-module;
  }
}

