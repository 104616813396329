.reset-password-page {
  .password-hint-container {
    display: grid;
    grid-template-columns: calc(50% + 8px) auto;
    font-size: 12px;
    color: #000000;
    margin-top: 16px;

    .hint-error {
      color: #f75245;
    }

    .hint-pass-ok {
      color: #d8d8d8;
      text-decoration: line-through;
    }

    .hint-left {
      display: inline-block;
      text-align: left;
    }
    .hint-right {
      display: inline-block;
      text-align: left;
    }
  }

  h1{
    margin-top: 33px;
    font-size: 28px;
  }
  .lbl {
    text-align: left;
    display: inline-block;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 8px;
    font-size: 14px;
    color: #000000;
  }

  .retype-password .lbl {
    margin-top: 20px;
  }

  .password-container{
    position: relative;
  }

  .password-icon {
    position: absolute;
    margin: 14px 6px;
    top: 0;
    right: 0;
  }

  .reset-password-button{
    margin-top: 32px;
    width: 100%;
    height: 52px;
    border: solid 1px #f8f8f8;
    font-size: 18px;
    font-weight: bold;
  }
}

.signup-invalid-link {
  margin: 117px 138px 8px;
}
