.toasts {
  position: fixed;
  top: 90px;
  right: 16px;
  z-index: 999;

  .toast-container {
    text-align: center;
    background: #34c25f;
    width: 400px;
    display: grid;
    grid-template-columns: auto 1fr;
    border-radius: 4px;
    position: absolute;
    right: -2000px;
    align-items: center;
    grid-gap: 9px;
    transition: right 0.3s;

    &.show {
      right: 0;
    }
  }

  .failure {
    background: #c2345f;
  }

  .toast-text {
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    text-align: left;
  }

  .toast-sign {
    margin-left: 14px;
    margin-top: 5px;
  }

  svg {
    path {
      fill: #fff;
    }
  }
}
