.select-select-box-recruiter-multi {

  .default-image-component {

    position: relative;
    display: flex;

    // img {
    //   margin-top: 4px;
    // }

  }
  .default-pic-frame, img {
    // margin-top: 4px;
    width: 24px;
    height: 24px;
    font-size: 12px;
    margin-left: 4px;
    border-radius: 50%;
    position: relative;
  }

  .lds-dual-ring:after {
    border-color: #fff transparent #fff transparent;
    width: 26px;
    height: 26px;
  }
  .modern-select-visible-part {
    border: inherit;
    border-radius: 25px;
    display: grid;
    grid-template-columns: 36px 1fr auto;
    height: 42px;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 7px;
    width: 240px;
    padding-right: 30px;

    .clear-button {
      background-color: transparent;
      color: var(--color-26);
      font-size: var(--size-314);
      margin-top: 1px;
      cursor: pointer;
    }

    .circle {
      margin-left: 4px;
      border: 12px solid var(--action-text-color);
      position: relative;

      span {
        position: absolute;
        margin-top: -6px;
        margin-left: -3px;
        font-size: 12px;
        width: 20px;
        left: -7px;
        right: 0;
        text-align: center;

      }
    }

  }
  .modern-select-visible-part-name, .modern-select-visible-part-counter {
    font-size: 14px;
  }
  .modern-select-visible-part-name {
    text-transform: capitalize;
    margin-left: 2px;

    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modern-select-visible-part-counter {
    color: #007dff;
    margin-left: 2px;
  }
  .modern-select-visible-part:before, .modern-select-visible-part:after {
    height: 6px;
    right: 20px;
    top: calc(50% - 2px);
    border-right: 2px solid;
    
  }
  .modern-select-visible-part:after {
    right: 16px;
  }
  .modern-select-list {
    width: 245px;
    left: -1px;
    margin-top: 8px;
    border-radius: 15px;
    padding-right: 40px;
    margin-right: 51px;
    height: 466px;
    z-index: 30;
  
    .custom-scroll {
      width: 229px;
      height: 439px;
      overflow: auto;
      padding-right: 5px;
      position: relative;
      right: 2px;
      margin-top: 4px; 
      
    }

    .default-image-component {

      &.online-status,  &.offline-status  {

        &:after {
          content: '•';
          position: absolute;
          color: var(--color-31);
          font-size: 18px;
          top: 12px;
          right: -3px;
          color: var(--color-31);
        }
      }

      &.offline-status {
        &:after {
          color: var(--color-7);
        }
      }
      
    }
  }
  .modern-select-list-item {
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
    cursor: pointer;
    padding: 0 0 0 10px;
    display: grid;
    grid-template-columns: auto 3fr 0.6fr;
    grid-gap: 10px;
    align-items: center;
    &.selected {
      color: var(--color-2);
    }
  }
  .modern-select-unvisible-part-name {
    text-align: left;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 5px;
    margin-left: 5px;
  }

  .modern-select-message-counter {
    display: inline;
    text-align: left;
  }
  // &.min-select-kkl {
  //   position: relative;
  //   .modern-select-visible-part-name {
  //     display: none;
  //   }
  //   .modern-select-visible-part {
  //     border: none;
  //     position: absolute;
  //     left: -79px;
  //     grid-template-columns: 34px auto;
  //     &:before, &:after {
  //       border-right: 2px solid;
  //       height: 9px;
  //       top: 41%;
  //       right: 16px;
  //     }
  //     &:after {
  //       right: 11px;
  //     }
  //   }
  //   .modern-select-list {
  //     width: 187px;
  //     top: 34px;
  //     left: -77px;
  //   }
  //   .modern-select-unvisible-part-name {
  //     color: #000;
  //     font-weight: 300;
  //     line-height: 1.28581;
  //   }
  // }
}