.process-actions {
  background: #fff;

  .process-actions-body {
    display: grid;
    justify-items: right;
    //grid-auto-columns: 53px;
    margin-top: 13px;
    justify-content: end;
    grid-gap: 0 4px;
    margin-right: 15px;

    .action-item {
      grid-row: 1;
      font-size: 25px;
      color: var(--action-text-color);
      cursor: pointer;
      position: relative;
      padding: 1px 0;
      border: 1px solid #1a99ff;
      border-radius: 4px;
      width: 84px;
      text-align: center;

      .button {
        font-size: 15px;
        width: 100px;
        padding: 6px 0;
        margin: 6px 8px;
        background: var(--action-text-color);
      }
    }

    .huj {
      background: var(--action-text-color);
      color: #fff;
      font-size: 12px;
      padding: 7px 5px;
      text-transform: uppercase;
    }
  }

  &.buttons {
    .process-actions-body {
      display: block;

      .action-item {
        display: inline-block;

        .dots-vertical {
          vertical-align: text-bottom;
        }

        .hidden-menu {
          top: 45px;
          width: 152px;
          right: -9px;

          .hidden-menu-item {
            padding: 10px 0 8px 15px;
          }
        }
      }
    }
  }
}


.actions {

  .process-actions {
    position: relative;
  }

  .process-actions-body {

    .icon-wrapper {
      display: grid;
      grid-template-columns: auto 1fr;
      width: fit-content;
      margin: 0 auto;
      grid-gap: 2px;
    }

    .action-item {

      p {
        font-size: var(--size-2);
        text-align: center;
        margin-top: 7px;
        color: var(--color-2);
        font-weight: bold;
      }

      svg {

        path {
          fill: var(--color-2);
        }
      }

      &.action-item-more-menu {
        position: absolute;
        right: 18px;
        margin-top: 4px;
        width: inherit;
      }
    }


  }
}

.profile-actions {
  .process-actions-body {
    display: block;
    margin: 0;

    .icon-wrapper {
      //width: 44px;
      //height: 44px;
      //background: var(--color-8);
      //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
      //border: transparent;
      //line-height: 2;
    }

    .action-item {
      margin-bottom: 21px;
      width: max-content;
    }

  }

  .process-actions {
    position: relative;
    margin: 68px 0 0 5px;
  }
}
