.job-summery-container {
  height: fit-content;
  border-radius: 4px;
  background-color: var(--color-8);
  padding: 24px 21px 15px;
  position: relative;
  margin-bottom: 50px;
  height: 830px;
  overflow-y: scroll;

  .action-item-more-menu {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  .edit-item {
    position: absolute;
    top: 28px;
    right: 32px;
  }

  .job-summery-container-info {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 18px;
    border-bottom: solid 1px #b5b5b5;
    margin: 4px 0 21px 0;
    padding-top: 24px;
    padding-bottom: 24px;

    .default-image-component{
      img,
      svg {
        border: solid 1px #979797;
        width: 62px;
        height: 62px;
      } 
    }

    .job-summery-container-info-wrap {
      display: grid;
      align-self: center;
      height: fit-content;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    a {
      font-size: 14px;
      line-height: 1;
      // color: var(--color-18);
      text-decoration: underline;
    }
  }

  .coordinator {
    margin-top: 0;
    padding: 0 0;

    .default-pic-frame {
      width: 40px;
      height: 40px;
      font-size: var(--size-4);
    }

    &.shadow4 {
      border: none;
      box-shadow: none;
    }

    button {
      background-color: var(--color-18);
    }
  }
  .background-info {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    align-items: baseline;
    margin: 0 0 17px;
    grid-gap: 2px 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    font-size: var(--size-4);
    padding-top: 23px;
    border-top: 1px solid #b5b5b5;

    &.borderless {
      border: none;
    }

    &.contact {
      border: none;
      margin: 0 0 13px;
      padding: 0;
    }

    .background-info-item {
      display: grid;
      grid-template-columns: 1.4fr 2fr;
      align-items: flex-start;
      margin: 2px;
    }

    legend {
      font-size: var(--size-314);
      line-height: var(--size-314);
      margin: 0;
      font-weight: bold;
    }

    .progress-text {
      font-size: 12px;
    }

    p {
      font-size: var(--size-314);
    }
  }  

}

.job-summery-button-container {
  display: grid;
  //grid-template-columns: repeat(2, 84px);
  grid-column-gap: 8px;
  justify-content: end;
  margin-right: 3px;
  height: 100%;
  align-items: center;

  .button-container {
    width: 84px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px var(--color-18);
    cursor: pointer;
    text-align: left;
    padding: 0;

    .row {
      svg {
        width: 12px;
        height: 11px;
        position: relative;
        justify-self: center;
        bottom: 1px;
      }

      .text {
        line-height: 1em;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--color-18);

        display: grid;
        grid-template-columns: 35% 35% auto;
        column-gap: 2px;
        align-items: center;
        height: 30px;
      }
    }
  }

  .right-part {
    display: grid;
    grid-template-columns: 60px auto;
    column-gap: 10px;

    margin-top: 0px;

    .dropdown-with-sub-directories {
      border: solid 1px #979797;
      padding: 0px 0px;
      border-radius: 4px;
      color: #000000;
      display: grid;
      grid-template-columns: 25px 37px auto;

      h2 {
        font-size: 16px;
        color: #000000;
        margin-right: 0px;
      }

      .arrow {
        margin-top: 12px;
      }
    }

    .job-published {
      .dropdown-with-sub-directories {
        padding-left: 7px;
        grid-template-columns: 22px auto;
      }

      svg {
        .published {
          g {
            stroke: #34c35f;
          }

          path {
            stroke: #34c35f;
          }
        }
      }
    }

    .job-status {
      .status {
        position: relative;
        margin-left: 10px;

        &:before {
          content: "•";
          color: var(--color-7);
        }

        &.open {
          &:before {
            color: var(--color-19);
          }
        }

        &.close {
          &:before {
            color: var(--color-7);
          }
        }

        &.hold {
          &:before {
            color: var(--color-24);
          }
        }
      }
    }

    .lds-dual-ring {
      right: 30%;
      left: auto;
      top: auto;
    }
  }
}

.job-summery-general-container {
  .header {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-18);
    margin-bottom: 1px;
  }

  .general-container {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    margin-bottom: 7px;
    color: var(--color-18);

    .key {
      align-self: start;
      font-size: 14px;
      font-weight: bold;
    }

    .value {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.job-summery-req-container {
  margin-top: 39px;
  margin-bottom: 24px;

  .header {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-18);
    margin-bottom: 2px;
  }

  .req-container {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-row-gap: 2px;
    color: var(--color-18);
    margin-bottom: 6px;

    .key {
      align-self: flex-start;
      font-size: 14px;
      font-weight: bold;
    }

    .value {
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;
    }

    &.transportation-wrapper {
      margin-top: 10px;
      
      
      .transportations {
        margin-top: 3px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 30px);
        
      }

    }
  }

  .skill-contr {
    margin-top: 17px;
  }

  .skills {
    .skill-tag {
      font-size: 12px;
      color: var(--color-18);
      background: transparent;

      &.mandatory {
        color: var(--color-8);
        background: var(--color-5);
      }
    }
  }
}

.job-summery-contact-at-company-container {
  margin-top: 36px;

  .header {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-18);
  }

  .container {
    display: grid;
    grid-template-columns: 20% 50% 30%;
    margin-top: 6px;
    align-items: center;

    .details {
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--color-18);
    }

    .details > div:nth-child(1) {
      font-size: 12px;
      font-weight: normal;
    }

    .details > div:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
    }

    .img {
      position: relative;
      top: 3px;

      svg {
        width: 40px;
      }
    }

    .buttons {
      text-align: right;
    }
  }
}

.job-summery-contact-at-recruiter-container {
  margin-top: 36px;

  .header {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-18);
  }

  .container {
    display: grid;
    grid-template-columns: 20% 50% 30%;
    margin-top: 6px;
    align-items: center;

    .details {
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--color-18);
    }

    .details > div:nth-child(1) {
      font-size: 12px;
      font-weight: normal;
    }

    .details > div:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
    }

    .img {
      position: relative;
      top: 3px;

      svg {
        width: 40px;
      }
    }

    .buttons {
      text-align: right;
    }
  }
}
