.fancy-switch-button2 {
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1fr;

  .text {
    font-size: var(--size-2);
    font-weight: normal;
    font-size: 14px;
    color: var(--color-10);

    &.text-checkbox-on {
      color: var(--color-31);
    }
  }

  .onoffswitch {
    position: relative;
    width: 40px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .onoffswitch-checkbox {
    display: none;
  }

  .onoffswitch-label {
    margin-top: 11px;
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 20px;
    padding: 0;
    line-height: 20px;
    border: 1px solid var(--color-10);
    border-radius: 20px;
    background-color: var(--color-8);
    transition: background-color 0.3s ease-in;
  }

  .onoffswitch-label:before {
    content: "";
    display: block;
    width: 20px;
    margin: 0;
    background: var(--color-8);
    position: absolute;
    top: 11px;
    bottom: 11px;
    right: 20px;
    border: 1px solid var(--color-10);
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: var(--color-31);
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    border-color: var(--color-31);
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0;
  }

}
