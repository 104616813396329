.progress-bar {
  width: 100%;
  height: 9px;
  background: var(--color-12);
  position: relative;
  border-radius: 17px;

  .progress-bar-progress {
    width: 0;
    background: var(--color-14);
    border-radius: 17px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .progress-text {
    position: absolute;
    right: -31px;
    font-size: 12px;
    top: -1px;
  }
}
