.complex-input2-wrapper {
    position: relative;
    z-index: 51;

    .main-input-wrapper {

        position: relative;
        cursor: pointer;

        &.open {
            border-bottom: solid 1px var(--color-1);
            padding-bottom: 2px;
            margin-bottom: 8px;
        }

        .main-input {

            padding-top: 8px;
            padding-left: 6px;
            margin-left: 0px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000000;
            z-index: 51;
            cursor: pointer;    
            box-shadow: none;
            border: none;
            border-radius: 0;
            height: 34px;
            background-color: transparent;

            &.open {
                color: black;
            }

        }

        .input-classes {
            border: none;
            border-bottom: 1px black;
        }
    
        .plus-icon {
            
            position: absolute;
            top: 2px;
            right: 0;
            padding-right: 0px;
            background-color: transparent;
            color: var(--color-2);
            display: grid;
            font-size: 24px;
            font-weight: normal;
            &:after,
            &:before {
                content: "+";
                display: block;
                position: absolute;
                right: 3px;
                top: 4px;
                z-index: 1;
                pointer-events: none;
            }
            cursor: pointer;
        }
        
        .arrow-icon {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            position: absolute;
            margin-left: 1px;
            padding-right: 0px;
            background-color: transparent;
            color: black;
            display: grid;
            top: 2px;
            right: 0;
            left: 0;
    
            &:after,
            &:before {
                border-right: 1px solid;
                content: "";
                display: block;
                height: 8px;
                position: absolute;
                transform: rotate(45deg);
                right: 5px;
                top: 15px;
                width: 0;
                z-index: 1;
                pointer-events: none;
            }
    
            &:after {
                transform: rotate(-45deg);
                right: 10px;
                z-index: 1;
            }
        }
    
    } 

    .tag-render {
        display: inline-block;
        padding: 4px 9.35px;
        margin-right: 2px;
        height: 24px;
        width: fit-content;
        border-radius: 12px;
        border: solid 1px #41a3fb;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        font-size: 12px;
        color: white;
        background-color: #41a3fb;
        margin-left: 5px;
        margin-bottom: 5px;
        margin-top: 8px;
        max-width: 190px;
        cursor: initial;
        position: relative;

        &:first-child {
            margin-top: 7px;
        }

        .change-icon, .close-icon {
          position: absolute;
          display: none;
          cursor: pointer;
        }

        .multi-render-item-label {
          display: block;
          top: initial;
          left: initial;
          position: relative;
          font-weight: normal;
          font-size: 12px;
          visibility: visible;  
          color: white; 
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }

        cursor: pointer;

          &:hover {
              border: solid 1px #41a3fb;
              background-color: #efefef;
              color: #efefef;
              cursor: default;
     
              .close-icon {
  
                  display: inline;
                  left: 50%;
                  transform: translate(calc(-100% + 8px), -5%);
                  
  
                  svg {
                      height: 16px;
                      width: 16px;
                  }
              }
  
              .multi-render-item-label {
                  visibility: hidden;
              }
          }
    }
  }