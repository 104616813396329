.talent-cv {
    .candidate-missing-cv {
        font-size: 28px;
        margin-top: 20px;
        text-align: center;
    }
    .cv-loader {
        h1 { 
            position: relative;
            top: 80px;
            color: #000;
        }
    }
    .header-not-edit {
        position: relative;
        display: grid;
        padding: 0 15px 0 0;
        background-color: #d0d0d0;
        grid-template-columns: auto 165px 20px;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 0 10px;

        .interview-missing {
            color: var(--color-7);
        }
        .disabled-events {
            cursor: not-allowed;
            pointer-events: none;
        }
        .edit-item {
            position: relative;
            top: 2px;
            cursor: pointer;

            .disabled-edit {
                svg {
                    path:nth-child(1) {
                        stroke: var(--color-17);
                    }
                    path:nth-child(2) {
                        fill: var(--color-17);
                    }
                }
            }
        }
        .status {
            position: relative;
            margin-left: 18px;

            p {
                line-height: 1.42;
            }
  
            &:before {
              content: '•';
              position: absolute;
              left: -9px;
              color: var(--color-7);
            }
  
            &.public {
              &:before {
                color: var(--color-19);
              }
            }
            &.not-public {
              &:before {
                color: var(--color-7);
              }
            }
            &.hold {
              &:before {
                color: var(--color-24);
              }
            }
  
          }

    }
    .header-edit-mode {
        display: grid;
        padding: 0 15px 0 0;
        background-color: #d0d0d0;
        //grid-template-columns: auto 30px 20px;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 0 10px;

        .modern-select-list {
            left: 23px;
            width: 100px;
        }

        .vee-icon-container {
            cursor: pointer;
            width: 19px;
            height: 19px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
        .close-icon-container {
            cursor: pointer;
            width: 15px;
            height: 15px;

            svg {
                width: 13px;
                height: 13px;
                path {
                    fill: red;
                }
            }
        }
    }

    .cv-tab {
        overflow: auto;
        .html-container {
            padding: 10px 40px;
            //pointer-events: none;
        }
        .html-container-for-edit {
            padding: 0;
            .trumbowyg-box, .trumbowyg-editor {
                 margin: 0;
                 height: 500px;
            }
        }
        .cv-wrapper {
            h1 {
                color: #000;
            }
        }
    }
}