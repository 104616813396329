#portal .modal-box {
  .process-overview-box {

    h2 {
      font-size: 24px;
      font-weight: normal;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: left;
      align-items: center;
      grid-gap: 5px;
    }

    .process-overview-company-name {
      font-size: 18px;
    }

    .process-overview-process {
      margin: 3px 0 10px 0;

      p {
        font-size: 12px;
        color: #979797;

        span {
          font-size: 14px;
          color: #000;
        }
      }
    }

    .process-overview-profile {
      display: grid;
      grid-template-columns: 30% 33.7% auto;
      margin: 40px 0 0 4px;
      border-bottom: 1px solid #b5b5b5;
      padding-bottom: 30px;
    }

    .process-overview-profile-item {
      display: grid;
      padding: 12px 0 0 0;

      p {
        font-size: 14px;
        color: #979797;
        margin-bottom: 6px;
      }

      .process-overview-profile-item-employment-position {
        font-weight: bold;
      }
    }

    .profile {
      padding: 0;

      .process-overview-profile-item-name {
        font-size: 18px;
        margin-top: 16px;
      }

      .default-pic-frame, img {
        width: 40px;
        height: 40px;
        font-size: 23px;
      }
    }

    .process-overview-actions-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      margin: 19px 0 0 2px;
    }

    .button {

      &.selected {
        border: 2px solid #000;
      }
    }

    .process-overview-process-bottom-data {
      margin-top: 40px;

      h3 {
        font-size: 20px;
        color: #000;
        display: block;
        margin-bottom: 12px;
      }

      p {
        font-size: 12px;
        color: #979797;

        span {
          font-size: 14px;
          color: #000;
        }
      }

      .radio {
        margin: 0.5rem;
        display: grid;
        height: 23px;
        align-items: end;
        position: relative;
      }

      .radio-label {
        font-size: 14px;
        font-weight: 300;
        display: grid;
        grid-template-columns: auto 1fr;
      }
    }
    
    .form-item{
      margin: 25px 0;
      width: 40%;
    }

    footer {
      display: grid;
      justify-items: end;
      margin: 25px 0 0 0;
    }
    
    .update-process{
      &.invalid{
        background: #808080;
      }
    }
  }
  
  .process-overview-box2{
    
    .process-overview-header{
      header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: baseline;
        grid-gap: 129px;
      }
    }
    
    .current-process-status{
      font-weight: bold;
      font-size: 18px;

      span{
        font-weight: normal;
      }
    }
    
    .process-overview-body{
      display: grid;
      grid-template-columns: 48% auto;
      grid-gap: 84px;
      padding: 30px 0 0 0;
    }
    
    .left-side{
      .item{
        margin-bottom: 21px;
      }
      
      .selector{
        margin-bottom: 0;
      }
      
      textarea{
        min-height: 125px;
      }

      .err-msg {
        color: var(--input-invalid-color);
      }
    }
    
    .modern-select{
      .modern-select-visible-part{
        font-size: 16px;
        
        span{
          font-weight: normal;
        }
      }
      
      .modern-select-list{
        left: 49px;
        width: 170px;
        
        .modern-select-list-item{
          font-size: 16px; 
        }
      }
    }
    
    .process-overview-process-bottom-data-item{
      margin-top: 25px;
      
      .multiselect-wrapper {
        max-width: 500px;
      }
      h3{
        font-weight: 600;
        color: #000;
        font-size: 18px;
        line-height: 2;
      }
      
      p{
        font-size: 14px;
      }
      
      span{
        color: #707070;
      }

      &.unmatched {
        margin-top: 0;

        .reason {
          display: inline-block;
          margin-bottom: 10px;
          font-size: 20px;
        }

        .form-item {
          margin-top: 10px;
        }
      }
    }
    
    .process-overview-process-bottom-data-item-radios{
      display: grid;
      grid-template-columns: 1fr 1fr;
      
      .radio{
        display: grid;
      }
      
      label{
        font-weight: 300;
        position: relative;
        margin-left: 16px;
        
        &::before{
          position: absolute;
          display: block;
          left: -25px;
        }
      }

      .new-company {
        margin-top: 15px;
        margin-left: 15px;
        margin-bottom: 15px;

        label {
          font-weight: bold;
          margin-left: 0;
        }
      }
      
    }
    
    .form-item{
      margin-bottom: 15px;
    }
    
    footer{
      .dont-send-expose-email-container {
        margin-top: 10px;
        display: grid;
        grid-template-columns: max-content max-content;
        column-gap: 10px;
        align-items: center;
      }
      .button{
        &.invalid{
          //background: #b5b5b5;
          color: #b5b5b5;
          font-weight: normal;
        }
      }
    }
  }
}
