.show-exists-processes {

}

.processes-already-exists {
  margin-bottom: 20px;

  .processes-already-exists-item {
    margin: 15px 0;

    .button{
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .processes-already-exists-item-list {
    display: grid;
    grid-template-columns: 20% auto;
    margin-bottom: 8px;

    .pervious-process {
      margin-bottom: 10px;

      label {
        font-size: 16px;
        font-weight: normal;
      }
    }

    a {
      display: inline-block;
      margin-top: 2px;
    }
  }
}
