@import '../../../styles/mixins.scss';

.nav-icon {
    width: 24px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: none;

    @include responsive-until($xl) {
      display: inline-block;
      margin-right: 40px;
    }

    @include responsive-until($lg) {
        margin-right: 32px;
    }

    span {
        background-color: var(--color-21);
        position: absolute;
        border-radius: 2px;
        transition: .3s cubic-bezier(.8, .5, .2, 1.4);
        width: 100%;
        height: 4px;
        transition-duration: 500ms;

        &:nth-child(1){
            top: 0;
            left: 0;
        }

        &:nth-child(2){
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            opacity: 1;
        }

        &:nth-child(3){
            bottom: 0;
            left: 0;
        }
    }

    &.open span:nth-child(1){
        transform: rotate(45deg);
        top: 8px;
    }

    &.open span:nth-child(2){
        opacity: 0;
    }

    &.open span:nth-child(3){
        transform: rotate(-45deg);
        top: 8px;
    }
}


  
  