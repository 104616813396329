.dashboard-activities-jobs-wrapper {

    .more-menu {
        position: relative;

        svg {
            position: absolute;
            top: 0;
            right: 25px;
        }
    }

    .column {
        margin-top: 25px;
        display: grid;
        grid-template-rows: repeat(2, 182.5px);

        .candidate-title {
            margin-left: 25px;    
            line-height: 1em;
        }

        .line1 {
            padding-left: 41px; 
            margin-top: 38px;
            display: grid;
            grid-template-columns: 72px auto;
            column-gap: 25px;
        }
    
        .bottom {
            margin-top: 37px;
            margin-left: 31px; 
            margin-right: 25px; 
        
            .line2 {
                display: grid;
                grid-template-columns: 92px auto;
                column-gap: 45px;
            }
        }
    
    }

}