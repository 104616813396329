.analytic-selection {
  background: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 16px;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    svg {
      cursor: pointer;
    }
  }

  .autocomplete {
    width: 220px;
  }
}
