.grid {
  display: grid;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-col-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
