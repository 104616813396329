.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  margin: auto;
  left: 0;
  right: 0;

  &::after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid;
    border-color: #1c2152 transparent #1c2152 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.full-loader {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--heading-color);
  opacity: 0.6;
}
