@import '../../../styles/mixins.scss';

.search-results-wrapper {

    width: initial;
    margin-top: 92px;

    .top {
        background-color: var(--color-8);

        .top-wrapper {
            max-width: 1536px;
            height: 68px;
            display: grid;
            margin: 0 auto;
            display: grid;
            align-items: center;

            .back-to-search-button {
                margin-left: auto;
                background-color: var(--color-21);
                color: white;
                font-weight: bold;
                
                @include responsive-from($sm) {
                    font-size: 16px;
                    padding: 8px 16px;
                    border-radius: 4px;
                }
            }
            
            svg {
                cursor: pointer;
            }
        }
        
    }

    .search-results-main {

        display: grid;
        grid-template-columns: 260px 843px 405px;
        column-gap: 14px;
        margin: 13px auto;
        padding-bottom: 70px;
        
        &.max-width {
            max-width: 1536px;
        }

        .lds-dual-ring {
            left: 10%;
        }

        .filter {
            height: 790px;
            overflow-y: auto;
            background-color: white;
            // padding-top: 18px;
            padding-bottom: 18px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;

            .filter-label {
                font-size: 20px;  
            }

            h1 {
                font-size: var(--size-314);
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: var(--color-18);
                margin: 0;
                // margin-left: 25px;
                // margin-bottom: 20px;
            }
        }

        .main {
            height: fit-content;
            background-color: white;
            padding-top: 20px;
            border: solid 1px var(--link-color);
            border-radius: 20px;

        }

        .right-leg {
            //background-color: white;

            // overflow-y: auto;
            //max-height: 790px;
        }

        .filter-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 24px;

            &-toggle {
                gap: 24px;
                padding: 8px 12px;
                border-radius: 8px;
                font-weight: bold;
                background-color: transparent !important;
        
                &.bp3-disabled {
                    border: 1px solid var(--color-12);
                }
        
                @include responsive-from($sm) {
                    display: none;
                }
            }
        }

        .add-to-matches > svg.bookmark {
            display: none;
        }
    }

    @include responsive-from($sm) {
        .filter-mobile-collapse {
            height: auto;
            overflow-y: visible;
    
            .bp3-collapse-body {
                display: block;
                transform: none !important;
            }
        }
    }

    @include mobile {
        .search-results-main {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 16px;
            margin: 0;
        }

        .top .top-wrapper {
            height: auto;
            padding: 16px;
        }

        .search-results-main .filter {
            height: auto;
            border-radius: 12px;
            padding-bottom: 0;
        }

        .search-results-main .filter-top {
            padding: 20px;
        }

        .search-results-filter-wrapper {
            padding: 0 16px;
        }

        .right-leg, header .all {
            display: none;
        }

        .search-results-title-wrapper header {
            display: flex;
            padding: 0 16px;

            .all {
                display: none;
            }

            .status-wrapper {
                margin: 0;
            }
        }

        // ITEM
        .search-results-item-wrapper {
            padding: 16px;
            
            .content .row1 {
                display: flex;
                gap: 24px;
                margin-left: 16px;

            }

            .profile-display + div {
                width: 100%;
            }

            .content .row1 .left-part {
                display: flex;
                flex-direction: column;
                width: 100%;
                
                & > * {
                    margin: 0;
                }

                & > .city {
                    width: 100%;
                    font-size: 16px;
                    color: #6C6C6C;
                    margin-bottom: 4px;
                }

                & > .availability {
                    display: none;
                }

                & > .stars {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translateY(4px);
                }

                .time-label {
                    color: #6C6C6C;
                }
            }

            .content .row1 .right-part {
                display: flex;
                gap: 16px;

                & > *:not(.activity-part) {
                    display: none;
                }

                .activity-part {
                    opacity: 1;
                    display: block;

                    & > *:not(.add-to-matches) {
                        display: none;
                    }

                    .add-to-matches > svg:first-child {
                        display: none;
                    }

                    .add-to-matches > svg.bookmark {
                        margin-top: 0;
                        display: inline-block;
                    }
                }
            }

            .content-wrapper > .customCheckbox,
            .content .update-details,
            .content .education-list button.arrow,
            .experience-list .button.arrow,
            .education-list, .experience-list {
                display: none;
            }

            .content .row1 .name a {
                font-size: 20px;
                font-weight: 500;
                color: #404040;
                line-height: normal;
            }

            .education-item {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .content .experience-list,
            .content .education-list {
                padding-left: 20px;
            }

            .content .skills {
                margin-left: 56px;
                margin-right: 0;
                margin-top: 16px;
            }

            .content .row2 {
                display: flex;
                gap: 24px;
                margin: 16px 0 0 16px;

                & > * {
                    display: flex;
                    flex-direction: column;
                }

                & > div:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
                    display: none;
                }

                .row h4 {
                    font-size: 12px;
                    font-weight: 400;
                    color: #A4A4A4;
                }

                .row h5 {
                    margin-left: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #6C6C6C;
                }
            }

            .row.recruiter {
                display: none;
            }

            .education-item .row > *:not(.institute-name, .degree-city),
            .experience-item .row > *:not(.company-name) {
                display: none;
            }

            .content .experience-list .experience-item {
                margin-left: 10px;
            }
        }
       
        .search-results-main {
            position: relative;

            .search-results-title-wrapper {
                position: absolute;
                top: 36px;
                right: 8px;

                & > header {
                    border: none;
                }

                .status .dropdown-with-sub-directories .hidden-menu {
                    left: auto;
                    right: 0;
                }
            }

            .main {
                border: none;
                background-color: transparent;
                padding-top: 0;
            }

            
            .search-results-list .search-item-main {
                border: none;
            }

            .search-results-item-wrapper,
            .search-result-job-item-wrapper {
                    height: 95%;
                    padding: 16px;
                    border: 1px solid #EEEFF2;
                    background-color: #fff;
                    border-radius: 12px;

                    .content-wrapper, .content {
                        margin-left: 0;
                        width: 100%;
                        height: 100%;
                    }
            }

            // JOB-ITEM
            .search-results-list .search-result-job-item-wrapper {

                .customCheckbox, .contact-person {
                    display: none;
                }
                
                h1 {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: normal;
                }

                .job-display img,
                .job-display .default-pic-frame {
                    width: 40px;
                    height: 40px;
                }

                .inner-row {
                    margin-left: 16px;
                    margin-right: 0;
                }

                .inner-row .row1 {
                    display: flex;
                    gap: 24px;

                    .left-part {
                        flex: 1;
                    }

                    .right-part {
                        display: block;
                    }

                    .right-part .activity-part {
                        opacity: 1;
                        display: block;
                        text-align: right;
                    }

                    .add-to-matches {
                        display: flex;
                    }

                    .add-to-matches > svg:first-child {
                        display: none;
                    }

                    .add-to-matches > svg.bookmark {
                        margin-top: 0;
                        display: inline-block;
                    }

                    .remove, .send-cv, .more-menu {
                        display: none;
                    }
                }

                .inner-row .row2 {
                    display: flex;
                    flex-direction: column;
                    margin-top: 0;

                    & > .date,
                    & > .status-bar,
                    & > .industry {
                        display: none;
                    }

                    label.city, label.company {
                        font-weight: 400;
                        color: #6C6C6C;
                        padding-right: 0;
                    }

                    label.city {
                        font-size: 14px;
                        &::after { content: none }
                    }
                }

                .more-details {
                    margin: 0;
                    padding-top: 24px;
                    padding-left: 56px;

                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    .column-one {
                        display: flex;
                        gap: 24px;

                        & > div:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
                            display: none;
                        }

                        .more-details-item {
                            display: flex;
                            flex-direction: column;
                        }

                        .more-details-title {
                            font-size: 12px;
                            font-weight: 400;
                            color: #A4A4A4;
                        }
    
                        .more-details-value {
                            font-size: 14px;
                            font-weight: 500;
                            color: #6C6C6C;
                        }
                    }

                    .column-two {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        .skills { 
                            margin: 0; 
                        }

                        .more-details-title {
                            font-size: 14px;
                            font-weight: 400;
                            color: #6C6C6C;
                        }
                    }

                    .column-three {
                        display: none;
                    }
                }
            }
        }
    }

    & > .search.recruiter {
        display: none;
    
        @include mobile {
          display: block;
        }
    }
}

.quill-bs-container {
    &.inbox {
  
      .ql-container {
        height: 250px;
        background: #fff;
      }
    }

    &.signature {

        margin-top: 5px;

        .ql-container {
            height: 150px;
            background: #fff;
        }  
    }

    .ql-editor {
        &::-webkit-scrollbar {
            width: 4px;
          }
        
        &::-webkit-scrollbar-thumb {
        background: #404040;
        border-radius: 2px;
        }
    
        &::-webkit-scrollbar-track {
        background: #ddd;
        border-radius: 2px;
        }
    }
  }