.quill-bs-container {
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
        content: 'Small';
        font-size: 12px !important;
    }
    
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
        content: 'Normal';
        font-size: 14px !important;
    }
    
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
        content: 'Large';
        font-size: 18px !important;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
        content: 'Huge';
        font-size: 24px !important;
    }

    .ql-editor > * {
        line-height: 1.86;
        
    }

    &.right-align {

        .ql-editor > * {
            direction: rtl;
            text-align: right;
        }
    }
}
